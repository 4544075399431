import { useEffect, useRef, useState } from "react";
import { HBK } from "../../http/dashboardApi";
import { loadScript } from "../../utils";
import styles from "./WidgetInstance.module.css";

interface Props {
  widget: HBK.Widget;
  propertyId: number;
}

const WidgetInstance = ({ widget, propertyId }: Props) => {
  const { type, colors, settings, id: widgetId } = widget;
  const ref = useRef<HTMLDivElement>(null);
  const widgetInstanceRef = useRef<Widgets.Instance | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.BookingSüdtirol) {
      setLoading(false);
      return;
    }

    loadScript("https://widget.bookingsuedtirol.com/v2/bundle.js").then(() =>
      setLoading(false),
    );
  }, []);

  useEffect(() => {
    if (loading) return;
    if (!ref.current) return;

    widgetInstanceRef.current?.unmount();
    widgetInstanceRef.current = getWidgetInstance(
      ref.current,
      type,
      settings,
      colors,
      widgetId,
      propertyId,
    );
  }, [loading, propertyId, colors, settings, widgetId]);

  return (
    <div className={styles.widgetWrapper}>
      <div
        style={{ backgroundColor: colors.main.background }}
        className={styles.widget}
        ref={ref}
      />
    </div>
  );
};

export default WidgetInstance;

const getWidgetInstance = (
  el: HTMLDivElement,
  type: HBK.Widget.Type,
  settings: HBK.Widget.Settings,
  colorScheme: HBK.Widget.ColorScheme,
  id: string,
  propertyId: number,
): Widgets.Instance | null => {
  if (!window.BookingSüdtirol) return null;

  switch (type) {
    case "booking":
      return window.BookingSüdtirol.Widgets.Booking(
        el,
        mapToBookingWidgetSettings(
          id,
          settings as HBK.Widget.Booking.Settings,
          colorScheme,
          propertyId,
        ),
      );
    case "enquiry":
      return window.BookingSüdtirol.Widgets.Enquiry(
        el,
        mapToEnquiryWidgetSettings(
          settings as HBK.Widget.Enquiry.Settings,
          colorScheme,
          id,
          propertyId,
        ),
      );
    case "quick_enquiry":
      return window.BookingSüdtirol.Widgets.QuickEnquiry(
        el,
        mapToQuickEnquiryWidgetSettings(
          settings as HBK.Widget.QuickEnquiry.Settings,
          colorScheme,
          id,
          propertyId,
        ),
      );
    case "special":
      return window.BookingSüdtirol.Widgets.Specials(
        el,
        mapToOfferingWidgetSettings(
          settings as HBK.Widget.Offering.Settings,
          colorScheme,
          id,
          propertyId,
        ),
      );
    case "room":
      return window.BookingSüdtirol.Widgets.Rooms(
        el,
        mapToOfferingWidgetSettings(
          settings as HBK.Widget.Offering.Settings,
          colorScheme,
          id,
          propertyId,
        ),
      );
    case "prices":
      return window.BookingSüdtirol.Widgets.Prices(
        el,
        mapToPricesWidgetSettings(
          settings as HBK.Widget.Price.Settings,
          colorScheme,
          id,
          propertyId,
        ),
      );
    case "weather":
      return window.BookingSüdtirol.Widgets.Weather(
        el,
        mapToWeatherWidgetSettings(
          settings as HBK.Widget.Weather.Settings,
          colorScheme,
          id,
          propertyId,
        ),
      );
    case "portal":
      return window.BookingSüdtirol.Widgets.Portal(
        el,
        mapToPortalWidgetSettings(
          settings as HBK.Widget.Portal.Settings,
          colorScheme,
          id,
        ),
      );
  }
};

const mapToBookingWidgetSettings = (
  id: string,
  settings: HBK.Widget.Booking.Settings,
  colorScheme: HBK.Widget.ColorScheme,
  propertyId: number,
): Widgets.Booking.Settings => {
  return {
    id,
    propertyId,
    colorScheme: mapWidgetColorScheme(colorScheme),
    layout: settings.layout,
    license: settings.license,
    defaultRoomPriceList: settings.default_room_price_list,
    enquiryType: settings.enquiry_type,
    overrideDefaultPriceListTitles: settings.override_default_price_list_titles,
    showDiscounts: settings.show_discounts,
    fieldSettings: settings.field_settings,
    maxAdults: settings.max_adults,
    maxChildren: settings.max_children,
  };
};

const mapToEnquiryWidgetSettings = (
  settings: HBK.Widget.Enquiry.Settings,
  colorScheme: HBK.Widget.ColorScheme,
  id: string,
  propertyId: number,
): Widgets.Enquiry.Settings => {
  return {
    id,
    propertyId,
    layout: settings.layout,
    fieldSettings: settings.field_settings,
    colorScheme: mapWidgetColorScheme(colorScheme),
  };
};

const mapToQuickEnquiryWidgetSettings = (
  settings: HBK.Widget.QuickEnquiry.Settings,
  colorScheme: HBK.Widget.ColorScheme,
  id: string,
  propertyId: number,
): Widgets.QuickEnquiry.Settings => {
  return {
    id,
    propertyId,
    colorScheme: mapWidgetColorScheme(colorScheme),
    layout: settings.layout,
    badgeStyle: settings.badge_style,
    fieldSettings: settings.field_settings,
  };
};

const mapToOfferingWidgetSettings = (
  settings: HBK.Widget.Offering.Settings,
  colorScheme: HBK.Widget.ColorScheme,
  id: string,
  propertyId: number,
): Widgets.Offering.Settings => {
  return {
    id,
    propertyId,
    colorScheme: mapWidgetColorScheme(colorScheme),
    layout: settings.layout,
    displayType: settings.display_type,
    enquirable: settings.enquirable,
    imageAspectRatio: settings.image_aspect_ratio,
    maxColumns: settings.max_columns,
    showFromPrice: settings.show_from_price,
    booking: {
      license: settings.booking.license,
      defaultRoomPriceList: settings.booking.default_room_price_list,
      overrideDefaultPriceListTitles:
        settings.booking.override_default_price_list_titles,
      showDiscounts: settings.booking.show_discounts,
      maxAdults: settings.booking.max_adults,
      maxChildren: settings.booking.max_children,
    },
    enquiry: {
      layout: settings.enquiry.layout,
      fieldSettings: settings.enquiry.field_settings,
    },
  };
};

const mapToPricesWidgetSettings = (
  settings: HBK.Widget.Price.Settings,
  colorScheme: HBK.Widget.ColorScheme,
  id: string,
  propertyId: number,
): Widgets.Prices.Settings => {
  return {
    id,
    propertyId,
    colorScheme: mapWidgetColorScheme(colorScheme),
    layout: settings.layout,
    checkRestrictions: settings.check_restrictions,
    grouping: settings.grouping,
  };
};

const mapToWeatherWidgetSettings = (
  settings: HBK.Widget.Weather.Settings,
  colorScheme: HBK.Widget.ColorScheme,
  id: string,
  propertyId: number,
): Widgets.Weather.Settings => {
  return {
    id,
    propertyId,
    colorScheme: mapWidgetColorScheme(colorScheme),
    layout: "layout_1",
    districtId: settings.district_id,
    iconStyle: settings.icon_style,
  };
};

const mapToPortalWidgetSettings = (
  settings: HBK.Widget.Portal.Settings,
  colorScheme: HBK.Widget.ColorScheme,
  id: string,
): Widgets.Portal.Settings => {
  return {
    id,
    colorScheme: mapWidgetColorScheme(colorScheme),
    layout: "layout_1",
    googleMapsApiKey: settings.google_maps_api_key,
    booking: {
      layout: settings.booking.layout,
      license: settings.booking.license,
      defaultRoomPriceList: settings.booking.default_room_price_list,
      enquiryType: settings.booking.enquiry_type,
      overrideDefaultPriceListTitles:
        settings.booking.override_default_price_list_titles,
      showDiscounts: settings.booking.show_discounts,
      fieldSettings: settings.booking.field_settings,
      maxAdults: settings.booking.max_adults,
      maxChildren: settings.booking.max_children,
    },
  };
};

const mapWidgetColorScheme = (
  colors: HBK.Widget.ColorScheme,
): Widgets.ColorScheme => {
  return {
    ...colors,
    main: {
      ...colors.main,
      separator: colors.main.separator ?? undefined,
      text: colors.main.text ?? undefined,
    },
  };
};
