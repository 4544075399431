import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import {
  FetcherOptions,
  Paginated,
  PaginationQuery,
  Searchable,
} from "../types";
import { wretchWithAddons } from "./wretch";

export namespace HBK {
  export enum Permission {
    AvailabilityRead = "availability:read",
    ChannelsRead = "channels:read",
    ChannelsWrite = "channels:write",
    PhotoRead = "photo:read",
    PhotoWrite = "photo:write",
    PropertyRead = "property:read",
    PropertyWrite = "property:write",
    PropertyCreate = "property:create",
    RoomTypeRead = "room_type:read",
    RoomTypeWrite = "room_type:write",
    WidgetRead = "widget:read",
    WidgetWrite = "widget:write",
    Admin = "admin",
    AdminAccountRead = "admin.account:read",
    AdminAccountWrite = "admin.account:write",
    AdminAlpineBitsRead = "admin.alpinebits:read",
    AdminAlpineBitsWrite = "admin.alpinebits:write",
    AdminGroupRead = "admin.group:read",
    AdminGroupWrite = "admin.group:write",
    AdminLTSRead = "admin.lts:read",
    AdminLTSWrite = "admin.lts:write",
    AdminMetasearchRead = "admin.metasearch:read",
    AdminMetasearchWrite = "admin.metasearch:write",
    AdminPortalRead = "admin.portal:read",
    AdminPortalWrite = "admin.portal:write",
    ReservationPolicyRead = "reservation_policy:read",
    ReservationPolicyWrite = "reservation_policy:write",
    RatePlanRead = "rate_plan:read",
    RatePlanWrite = "rate_plan:write",
  }

  export interface Error {
    message: string;
  }

  export interface ErrorResponse {
    error: Error;
  }

  export type Language = "de" | "en" | "it" | "nl" | "fr";

  export type TranslatedField = Partial<Record<Language, string>>;

  export interface PaymentCard {
    id: string;
    name: string;
    selected: boolean;
  }

  export type AmenityStatus = "selected" | "favored";

  export interface AmenityGroup {
    id: string;
    title: string;
  }

  export interface Amenity {
    id: string;
    title: string;
    group: AmenityGroup;
    status: AmenityStatus | null;
    importable: boolean;
  }

  export interface PropertyAmenityBody {
    id: string;
    status: AmenityStatus;
  }

  export interface HouseRules {
    check_in: TimeWindow;
    check_out: TimeWindow;
    children_allowed: boolean;
    children_min_age: number;
    cutoff_hours: string;
  }

  export interface BasicProperty extends HouseRules {
    name: string;
    codici_identificativi_nazionali: string[];
    category: PropertyCategory;
    rating: number;
    is_superior: boolean;
    email: string;
    phone_numbers: string[];
    listing_status: ListingStatus;
    guest_interaction: GuestInteraction;
    languages: HBK.Language[];
    description: TranslatedField;
  }

  export type PropertyBody = Partial<BasicProperty & { address: AddressBody }>;

  export interface Property extends BasicProperty {
    id: number;
    address: Address;
  }

  export type PropertySource = "direct" | "group";

  export interface AssignedProperty extends HBK.Property {
    sources: PropertySource[];
  }

  export interface Place {
    id: string;
    subdivision: string;
    municipality: string;
    postal_code: string;
    full_address: string;
    latitude: number;
    longitude: number;
  }

  export interface AddressBody {
    place_id: string;
    longitude: number;
    latitude: number;
    street: TranslatedField;
  }

  export type Address = Pick<
    AddressBody,
    "longitude" | "latitude" | "street"
  > & { place: Place };

  export type Division = "area" | "municipality" | "subdivision";

  export const propertyCategories = [
    "hotel",
    "residence",
    "bed_and_breakfast",
    "farm_holiday",
    "mountain_inn",
    "campground",
    "holiday_home",
    "youth_hostel",
    "guest_house",
    "mountain_refuge",
    "bed_and_breakfast_hotel",
    "inn",
  ] as const;

  export type PropertyCategory = (typeof propertyCategories)[number];

  export interface Topic {
    id: string;
    title: string;
    requirements: string[];
    selected: boolean;
  }

  export interface TimeWindow {
    start: string;
    end: string;
  }

  export const listingStatuses = ["listed", "unlisted"] as const;

  export type ListingStatus = (typeof listingStatuses)[number];

  export const guestInteractions = ["formal", "informal"] as const;

  export type GuestInteraction = (typeof guestInteractions)[number];

  export type PayoutProfileId = "bank_account" | "on_site";

  export interface PayoutProfile {
    id: PayoutProfileId;
    description: string;
    editable: boolean;
  }

  export interface BankAccount {
    name: string;
    iban: string;
    swift: string;
  }

  export interface PhotoUploadURL {
    object_id: string;
    url: string;
    fields: Record<string, string>;
  }

  export interface MovePhotoBody {
    previous?: string;
  }

  export interface PhotoBody {
    id: string;
    room_type_id?: string | undefined;
    rate_plan_id?: string | undefined;
  }

  export interface Photo extends PhotoBody {
    url: string;
    width: number;
    height: number;
    size: number;
    copyright?: string;
    description?: TranslatedField;
    created_at: string;
  }

  interface RoomTypeOccupancy {
    min: number;
    max: number;
    default: number;
  }

  export interface LivingAreas {
    bedrooms: number;
    dining_rooms: number;
    bathrooms: number;
    water_closets: number;
    living_rooms: number;
  }

  export const roomCategories = ["room", "apartment", "camping_pitch"] as const;

  export type RoomCategory = (typeof roomCategories)[number];

  export interface RoomBody {
    code: string;
  }

  export interface Room extends RoomBody {
    id: string;
  }

  interface BasicRoomType {
    code: string;
    category: RoomCategory;
    name: TranslatedField;
    description: TranslatedField;
    occupancy: RoomTypeOccupancy;
    size: number;
    living_areas: LivingAreas;
  }

  export type RoomTypeBody = Partial<BasicRoomType>;

  export interface RoomType extends BasicRoomType {
    id: string;
    created_at: string;
  }

  export interface RoomAvailability {
    room_id: string;
    room_code: string;
    room_type_id: string;
    dates: RoomAvailabilityDates;
  }

  export type RoomAvailabilityDates = Record<string, boolean>;

  export namespace LTS {
    export interface TourismOrganization {
      lts_id: string;
      name: string;
    }

    export interface Photo {
      url: string;
      width: number;
      height: number;
      copyright: string;
    }
  }

  export type WidgetProperty = Pick<Property, "id" | "name">;

  export type PostWidgetPropertyBody = Pick<WidgetProperty, "id">;

  export type PatchWidgetBody = Partial<{
    colors: Widget.ColorScheme;
    settings: Widget.Settings;
  }>;

  export interface PostWidgetBody extends PatchWidgetBody {
    type: Widget.Type;
  }

  export interface WidgetPortal {
    id: string;
    name: string;
  }

  export interface PropertyWidget extends Widget {
    property: WidgetProperty;
  }

  export interface PortalWidget extends Widget {
    portal: WidgetPortal;
  }

  export interface Widget {
    id: string;
    type: Widget.Type;
    colors: Widget.ColorScheme;
    settings: Widget.Settings;
    default_colors: boolean;
    created_at: string;
    easy_channel_id?: number;
    bookable: boolean;
  }

  export type WidgetResponse = PropertyWidget | PortalWidget;

  export namespace Widget {
    export type Type = (typeof types)[number];

    export const types = [
      "booking",
      "enquiry",
      "quick_enquiry",
      "special",
      "room",
      "prices",
      "weather",
      "portal",
    ] as const;

    export type FieldSetting = (typeof fieldSettings)[number];

    export const fieldSettings = ["disabled", "optional", "required"] as const;

    export interface ColorScheme {
      main: {
        background: string;
        title: string;
        text: string | null;
        separator: string | null;
      };
      primary: {
        background: string;
        text: string;
      };
      secondary: {
        background: string;
        text: string;
      };
    }

    export type Settings =
      | Booking.Settings
      | Enquiry.Settings
      | QuickEnquiry.Settings
      | Offering.Settings
      | Price.Settings
      | Weather.Settings
      | Portal.Settings;

    export type FieldSettingType =
      | "gender"
      | "phone"
      | "street"
      | "zipcode"
      | "city"
      | "country"
      | "note"
      | "stay"
      | "occupancies"
      | "newsletter"
      | "guests";

    export type FieldSettings = Record<
      FieldSettingType,
      HBK.Widget.FieldSetting
    >;

    export namespace Booking {
      export type Layout = (typeof layouts)[number];
      export const layouts = ["one_pager", "four_steps", "compact"] as const;

      export type License = (typeof licenses)[number];
      export const licenses = ["standard", "extended"] as const;

      export type DefaultRoomPriceList = (typeof defaultRoomPriceLists)[number];
      export const defaultRoomPriceLists = ["default", "cheapest"] as const;

      export type EnquiryType = (typeof enquiryTypes)[number];
      export const enquiryTypes = [
        "disabled",
        "non_bookable_items",
        "all_items",
      ] as const;

      export interface Settings {
        layout: Layout;
        license: License;
        default_room_price_list: DefaultRoomPriceList;
        enquiry_type: EnquiryType;
        show_discounts: boolean;
        override_default_price_list_titles: boolean;
        field_settings: FieldSettings;
        max_adults: number;
        max_children: number;
      }
    }

    export namespace Enquiry {
      export type Layout = (typeof layouts)[number];
      export const layouts = ["layout_1", "layout_2", "layout_3"] as const;

      export interface Settings {
        layout: Layout;
        field_settings: FieldSettings;
      }
    }

    export namespace QuickEnquiry {
      export type Layout = (typeof layouts)[number];
      export const layouts = ["bottom_left", "bottom_right"] as const;

      export type BadgeStyle = (typeof badgeStyles)[number];
      export const badgeStyles = [
        "text",
        "icon_square",
        "icon_circle",
      ] as const;

      export interface Settings {
        layout: Layout;
        badge_style: BadgeStyle;
        field_settings: FieldSettings;
      }
    }

    export namespace Offering {
      export type Layout = (typeof layouts)[number];
      export const layouts = [
        "layout_1",
        "layout_2",
        "layout_3",
        "layout_4",
        "layout_5",
        "layout_6",
      ] as const;

      export type DisplayType = (typeof displayTypes)[number];
      export const displayTypes = ["tiles", "slides"] as const;

      export type ImageAspectRatio = (typeof imageAspectRatios)[number];
      export const imageAspectRatios = [
        0.75, 0.5625, 0.375, 0.25, 1.3333, 1.7778, 2.6667, 1,
      ] as const;

      export interface BookingSettings {
        license: Booking.License;
        default_room_price_list: Booking.DefaultRoomPriceList;
        show_discounts: boolean;
        override_default_price_list_titles: boolean;
        max_adults: number;
        max_children: number;
      }

      export interface Settings {
        layout: Layout;
        max_columns: number;
        display_type: DisplayType;
        image_aspect_ratio: ImageAspectRatio;
        show_from_price: boolean;
        enquirable: boolean;
        booking: BookingSettings;
        enquiry: Enquiry.Settings;
      }
    }

    export namespace Price {
      export type Layout = (typeof layouts)[number];
      export const layouts = ["layout_1", "layout_2", "layout_3"] as const;

      export type Grouping = (typeof groupings)[number];
      export const groupings = ["offer", "board"] as const;

      export interface Settings {
        layout: Layout;
        grouping: Grouping;
        check_restrictions: boolean;
      }
    }

    export namespace Weather {
      export type Layout = (typeof layouts)[number];
      export const layouts = ["layout_1"] as const;

      export type IconStyle = (typeof iconStyles)[number];
      export const iconStyles = ["colored", "monochrome"] as const;

      export type DistrictId = (typeof districtIds)[number];
      export const districtIds = [0, 1, 2, 3, 4, 5, 6, 7] as const;

      export interface Settings {
        layout: Layout;
        icon_style: IconStyle;
        district_id: DistrictId;
      }
    }

    export namespace Portal {
      export type Layout = (typeof layouts)[number];
      export const layouts = ["layout_1"] as const;

      export interface Settings {
        layout: Layout;
        google_maps_api_key: string | null;
        booking: Booking.Settings;
      }
    }
  }

  export interface UserTraits {
    name?: string;
    email: string;
  }

  export type Roles = (typeof roles)[number];
  export const roles = ["admin", "member"] as const;

  export interface PostRole {
    id: Roles;
  }

  export interface Role extends PostRole {
    permissions: Permission[];
  }

  export interface User {
    id: string;
    created_at: string;
    role: Role;
    traits?: UserTraits;
  }

  export interface InvitationBody {
    name: string;
    email: string;
    role: PostRole;
  }

  export interface Invitation {
    id: string;
    name: string;
    email: string;
    role: Role;
    property: {
      id: number;
      name: string;
    };
    created_at: string;
    expires_at: string;
  }

  export interface Channel {
    id: string;
    name: string;
    protocol: Channel.Protocol;
    created_at: string;
    last_synced_update?: string;
    is_active: boolean;
    is_outdated: boolean;
    tracking_id?: string;
  }

  export namespace Channel {
    export type Status = "markedForDeletion" | "paired" | "unpaired" | "none";

    export type Protocol = "alpine_bits" | "channex" | "tomas";

    export interface RoomType {
      id: string;
      name: string;
      code: string;
      status: Status;
      selected: boolean;
    }

    export type BoardType =
      | "all_inclusive"
      | "bed_and_breakfast"
      | "full_board"
      | "half_board"
      | "room_only"
      | "unknown";

    export interface Board {
      board: BoardType;
      code: string;
      id: string;
      status: Status;
      selected: boolean;
    }

    export interface RatePlan {
      id: string;
      name: string;
      boards: Board[];
    }

    export interface RatePlans {
      rate_plans: RatePlan[];
      specials: RatePlan[];
    }

    export interface PutRoomTypes {
      ids: string[];
    }

    export interface PutRatePlans {
      ids: string[];
    }
  }

  export namespace Tomas {
    export interface PairingBody {
      id: string;
      property: {
        id: number;
      };
      user_id: number;
      hotel_id: number;
      api_key: string;
    }

    export interface Pairing extends PairingBody {
      created_at: string;
      property: {
        id: number;
        name: string;
      };
    }

    export interface ConfigBody {
      rooms: Room[];
      rate_plans: RatePlans[];
    }

    export interface Config {
      enabled: boolean;
      rooms: Room[];
      rate_plans: ConfigRatePlans;
      synced_at: string;
    }

    export interface Room {
      room_id: string;
      title: string;
      selected: Inventory | undefined;
      rooms: Inventory[];
    }

    export interface ConfigRatePlans {
      rate_plans: MappingRatePlans;
      not_mapped_rate_plans: Inventory[];
      alpine_bits_rate_plans: Inventory[];
    }

    export interface Inventory {
      id: string;
      title: string;
    }

    export interface RatePlan {
      rate_plan_id: string;
      title: string;
      checked: boolean;
    }

    export interface RatePlans {
      tomas_id: string;
      base_plan: Inventory | undefined;
      rate_plans: RatePlan[];
    }

    export type MappingRatePlans = Record<string, RatePlans>;
  }

  export const reservationSecurity = [
    "deposit",
    "credit_card_required",
    "no_credit_card_required",
  ] as const;

  export type ReservationSecurity = (typeof reservationSecurity)[number];

  export interface CancellationPolicy {
    fee_percentage: number;
    start_day: number | null;
    end_day: number;
  }

  export interface ReservationPolicyBody {
    name: string;
    security: ReservationSecurity;
    deposit_percentage?: number;
    credit_card_allowed: boolean;
    bank_transfer_allowed: boolean;
    bank_transfer_days_before_arrival: number;
    cancellation_policies: CancellationPolicy[];
  }

  export interface ReservationPolicy extends ReservationPolicyBody {
    id: string;
    is_readonly: boolean;
    created_at: string;
  }

  export interface ChannelRatePlan {
    code: string;
    title: TranslatedField;
  }

  type ChargeType = "per_person" | "per_room";

  export type DaysOfWeek = boolean[];

  interface RatePlanOccupancy {
    min: number;
    max?: number;
    min_age: number;
  }

  export interface RatePlanSettings {
    charge_type: ChargeType;
    min_stay_arrival: number;
    max_stay_arrival: number;
    arrival_days: DaysOfWeek;
    departure_days: DaysOfWeek;
    adult_occupancy: RatePlanOccupancy;
    child_occupancy: RatePlanOccupancy;
  }

  export interface PatchRatePlanTranslationsBody {
    title?: TranslatedField;
    description?: TranslatedField;
  }

  export interface RatePlanTranslations extends PatchRatePlanTranslationsBody {
    locked: boolean;
  }

  export interface ReservationPolicyPairingBody {
    id: string;
  }

  interface RatePlanConfigurationStatus {
    is_missing_reservation_policy: boolean;
    is_missing_translations: boolean;
  }

  export interface RatePlan {
    id: string;
    channel_code: string;
    channel_title?: TranslatedField;
    settings: RatePlanSettings;
    translations: RatePlanTranslations;
    created_at: string;
    reservation_policy_id?: string;
    configuration_status: RatePlanConfigurationStatus;
  }
}

const api = wretchWithAddons.options({ credentials: "include" }).url("/api");

const fetcher = <Response>({ url, query, signal }: FetcherOptions) => {
  return api
    .options(signal ? { signal } : {})
    .url(url)
    .query(query ?? {})
    .get()
    .json<Response>();
};

const isError = (value: unknown): value is HBK.Error =>
  typeof value === "object" &&
  value !== null &&
  "message" in value &&
  typeof (value as HBK.Error).message === "string";

const isErrorResponse = (value: unknown): value is HBK.ErrorResponse =>
  typeof value === "object" &&
  value !== null &&
  "error" in value &&
  isError((value as HBK.ErrorResponse).error);

export const tryParseErrorResponse = (
  error: unknown,
): HBK.ErrorResponse | undefined => {
  if (!(error instanceof Error)) return;

  try {
    const parsed = JSON.parse(error.message);
    return isErrorResponse(parsed) ? parsed : undefined;
  } catch (err) {
    console.error(err);
    return;
  }
};

export const useMe = (userId: string | undefined) =>
  useSWRImmutable(
    userId
      ? {
          url: "/me",
          args: userId,
        }
      : null,
    fetcher<{
      id: string;
      permissions: HBK.Permission[];
    }>,
  );

export const usePlaces = (opts: Searchable) =>
  useSWR(
    {
      url: "/places",
      ...opts,
    },
    (opts) => fetcher<{ places: HBK.Place[] }>(opts).then((res) => res.places),
    {
      keepPreviousData: true,
    },
  );

export const useProperties = (
  query: PaginationQuery & {
    "id[]"?: number[];
  },
) =>
  useSWR({ url: `/properties`, query }, (opts) =>
    fetcher<Paginated<{ properties: HBK.Property[] }>>(opts),
  );

export const useSearchProperties = (opts: Searchable) =>
  useSWR(
    opts.query.q ? { url: `/properties`, ...opts } : null,
    (opts) =>
      fetcher<Paginated<{ properties: HBK.Property[] }>>(opts).then(
        (res) => res.properties,
      ),
    {
      keepPreviousData: true,
    },
  );

export const useProperty = (propertyId: number) =>
  useSWR({ url: `/properties/${propertyId}` }, (opts) =>
    fetcher<HBK.Property>(opts),
  );

export const patchProperty = (propertyId: number, body: HBK.PropertyBody) =>
  api.url(`/properties/${propertyId}`).patch(body).json<HBK.Property>();

export const usePropertyAmenities = (propertyId: number) =>
  useSWR({ url: `/properties/${propertyId}/amenities` }, (opts) =>
    fetcher<{
      amenities: HBK.Amenity[];
    }>(opts),
  );

export const importPropertyAmenities = (propertyId: number) =>
  api.url(`/properties/${propertyId}/amenities/import`).post().res();

export const putPropertyAmenity = (
  propertyId: number,
  amenityId: string,
  status: HBK.AmenityStatus,
) =>
  api
    .url(`/properties/${propertyId}/amenities/${amenityId}`)
    .put({
      status,
    })
    .res();

export const deletePropertyAmenity = (propertyId: number, amenityId: string) =>
  api.url(`/properties/${propertyId}/amenities/${amenityId}`).delete().res();

export const usePropertyTopics = (propertyId: number) =>
  useSWR({ url: `/properties/${propertyId}/topics` }, (opts) =>
    fetcher<{
      topics: HBK.Topic[];
    }>(opts),
  );

export const putPropertyTopic = (propertyId: number, topicId: string) =>
  api.url(`/properties/${propertyId}/topics/${topicId}`).put().res();

export const deletePropertyTopic = (propertyId: number, topicId: string) =>
  api.url(`/properties/${propertyId}/topics/${topicId}`).delete().res();

export const usePropertyPaymentCards = (propertyId: number) =>
  useSWR({ url: `/properties/${propertyId}/payment_cards` }, (opts) =>
    fetcher<{
      cards: HBK.PaymentCard[];
    }>(opts),
  );

export const postPropertyPaymentCards = (propertyId: number, cards: string[]) =>
  api
    .url(`/properties/${propertyId}/payment_cards`)
    .post({
      cards,
    })
    .res();

export const usePropertyPayoutProfiles = (propertyId: number) =>
  useSWR({ url: `/properties/${propertyId}/payout_profiles` }, (opts) =>
    fetcher<{
      profiles: HBK.PayoutProfile[];
    }>(opts),
  );

export const deletePropertyBankAccount = (propertyId: number) =>
  api.url(`/properties/${propertyId}/bank_account`).delete().res();

export const postPropertyBankAccount = (
  propertyId: number,
  body: HBK.BankAccount,
) => api.url(`/properties/${propertyId}/bank_account`).post(body).res();

export const usePropertyLTSPhotos = (
  propertyId: number,
  query: PaginationQuery,
) =>
  useSWR(
    { url: `/properties/${propertyId}/lts/photos`, query },
    (opts) => fetcher<Paginated<{ photos: HBK.LTS.Photo[] }>>(opts),
    {
      keepPreviousData: true,
    },
  );

export const importPropertyLTSPhoto = (
  propertyId: number,
  ltsPhotoId: string,
) =>
  api.url(`/properties/${propertyId}/lts/photos/${ltsPhotoId}`).post().json<{
    object_id: string;
  }>();

export const usePhotos = (
  propertyId: number,
  query: { room_type_id: string | undefined; rate_plan_id: string | undefined },
) =>
  useSWR(
    { url: `/properties/${propertyId}/photos`, query },
    (opts) => fetcher<{ photos: HBK.Photo[] }>(opts),
    {
      keepPreviousData: true,
    },
  );

export const createPhotoUploadURL = (propertyId: number) =>
  api
    .url(`/properties/${propertyId}/photos/create_upload_url`)
    .post()
    .json<HBK.PhotoUploadURL>();

export const postPhoto = (propertyId: number, body: HBK.PhotoBody) =>
  api.url(`/properties/${propertyId}/photos`).post(body).json<{
    photos: HBK.Photo[];
  }>();

export const movePhoto = (
  propertyId: number,
  photoId: string,
  opts: {
    previous: string | undefined;
  },
) =>
  api
    .url(`/properties/${propertyId}/photos/${photoId}/move`)
    .query(opts)
    .post()
    .res();

export const deletePhoto = (propertyId: number, photoId: string) =>
  api.url(`/properties/${propertyId}/photos/${photoId}`).delete().res();

export const useRoomTypes = (propertyId: number, query: PaginationQuery) =>
  useSWR(
    { url: `/properties/${propertyId}/room_types`, query },
    (opts) => fetcher<Paginated<{ room_types: HBK.RoomType[] }>>(opts),
    {
      keepPreviousData: true,
    },
  );

export const postRoomType = (propertyId: number, body: HBK.RoomTypeBody) =>
  api
    .url(`/properties/${propertyId}/room_types`)
    .post(body)
    .json<HBK.RoomType>();

export const useRoomType = (roomTypeId: string) =>
  useSWR(
    { url: `/room_types/${roomTypeId}` },
    (opts) => fetcher<HBK.RoomType>(opts),
    {
      keepPreviousData: true,
    },
  );

export const patchRoomType = (roomTypeId: string, body: HBK.RoomTypeBody) =>
  api.url(`/room_types/${roomTypeId}`).patch(body).json<HBK.RoomType>();

export const deleteRoomType = (roomTypeId: string) =>
  api.url(`/room_types/${roomTypeId}`).delete().res();

export const useRoomTypeAmenities = (roomTypeId: string) =>
  useSWR({ url: `/room_types/${roomTypeId}/amenities` }, (opts) =>
    fetcher<{
      amenities: HBK.Amenity[];
    }>(opts),
  );

export const putRoomTypeAmenity = (roomTypeId: string, amenityId: string) =>
  api.url(`/room_types/${roomTypeId}/amenities/${amenityId}`).put().res();

export const deleteRoomTypeAmenity = (roomTypeId: string, amenityId: string) =>
  api.url(`/room_types/${roomTypeId}/amenities/${amenityId}`).delete().res();

export const useRooms = (roomTypeId: string) =>
  useSWR(
    { url: `/room_types/${roomTypeId}/rooms` },
    (opts) => fetcher<{ rooms: HBK.Room[] }>(opts),
    {
      keepPreviousData: true,
    },
  );

export const putRooms = (roomTypeId: string, codes: string[]) =>
  api
    .url(`/room_types/${roomTypeId}/rooms`)
    .put({ rooms: codes })
    .json<{ rooms: HBK.Room[] }>();

export const useAvailabilities = (
  propertyId: number,
  query: { start: string; end: string },
) =>
  useSWR({ url: `/properties/${propertyId}/availabilities`, query }, (opts) =>
    fetcher<{ availabilities: HBK.RoomAvailability[] }>(opts),
  );

export const usePropertyWidgets = (
  propertyId: number,
  query: PaginationQuery,
) =>
  useSWR({ url: `/properties/${propertyId}/widgets`, query }, (opts) =>
    fetcher<Paginated<{ widgets: HBK.PropertyWidget[] }>>(opts),
  );

export const postPropertyWidgets = (
  propertyId: number,
  body: HBK.PostWidgetBody,
) =>
  api
    .url(`/properties/${propertyId}/widgets`)
    .post(body)
    .json<HBK.WidgetResponse>();

export const useWidget = (widgetId: string) =>
  useSWR({ url: `/widgets/${widgetId}` }, (opts) =>
    fetcher<HBK.WidgetResponse>(opts),
  );

export const patchWidget = (widgetId: string, body: HBK.PatchWidgetBody) =>
  api.url(`/widgets/${widgetId}`).patch(body).json<HBK.WidgetResponse>();

export const usePropertyUsers = (propertyId: number, query: PaginationQuery) =>
  useSWR({ url: `/properties/${propertyId}/users`, query }, (opts) =>
    fetcher<Paginated<{ users: HBK.User[] }>>(opts),
  );

export const deletePropertyUser = (propertyId: number, userId: string) =>
  api.url(`/properties/${propertyId}/users/${userId}`).delete().res();

export const usePropertyInvitations = (
  propertyId: number,
  query: PaginationQuery<{
    show_declined?: boolean;
  }>,
) =>
  useSWR({ url: `/properties/${propertyId}/invitations`, query }, (opts) =>
    fetcher<Paginated<{ invitations: HBK.Invitation[] }>>(opts),
  );

export const postPropertyInvitation = (
  propertyId: number,
  body: HBK.InvitationBody,
) => api.url(`/properties/${propertyId}/invitations`).post(body).res();

export const deleteInvitation = (invitationId: string) =>
  api.url(`/invitations/${invitationId}`).delete().res();

export const useInvitations = (
  query: PaginationQuery<{
    show_declined?: boolean;
  }>,
) =>
  useSWR({ url: `/invitations`, query }, (opts) =>
    fetcher<Paginated<{ invitations: HBK.Invitation[] }>>(opts),
  );

export const acceptInvitation = (invitationId: string) =>
  api.url(`/invitations/${invitationId}/accept`).put().res();

export const declineInvitation = (invitationId: string) =>
  api.url(`/invitations/${invitationId}/decline`).put().res();

export const usePendingInvitationsCount = () =>
  useSWR({ url: "/invitations", query: { page: 1, limit: 1 } }, (opts) =>
    fetcher<Paginated<{ invitations: HBK.Invitation[] }>>(opts).then(
      (r) => r.total,
    ),
  );
export const useChannels = (propertyId: number, query: PaginationQuery) =>
  useSWR({ url: `/properties/${propertyId}/channels`, query }, (opts) =>
    fetcher<Paginated<{ channels: HBK.Channel[] }>>(opts),
  );

export const useChannel = (propertyId: number, channelId: string) =>
  useSWR({ url: `/properties/${propertyId}/channels/${channelId}` }, (opts) =>
    fetcher<HBK.Channel>(opts),
  );

export const useChannelRoomTypes = (propertyId: number, channelId: string) =>
  useSWR(
    { url: `/properties/${propertyId}/channels/${channelId}/room_types` },
    (opts) => fetcher<{ room_types: HBK.Channel.RoomType[] }>(opts),
  );

export const useChannelRatePlans = (propertyId: number, channelId: string) =>
  useSWR(
    { url: `/properties/${propertyId}/channels/${channelId}/rate_plans` },
    (opts) => fetcher<HBK.Channel.RatePlans>(opts),
  );

export const putChannel = (propertyId: number, channelId: string) =>
  api.url(`/properties/${propertyId}/channels/${channelId}`).put().res();

export const deleteChannel = (propertyId: number, channelId: string) =>
  api.url(`/properties/${propertyId}/channels/${channelId}`).delete().res();

export const putChannelRoomTypes = (
  propertyId: number,
  channelId: string,
  body: HBK.Channel.PutRoomTypes,
) =>
  api
    .url(`/properties/${propertyId}/channels/${channelId}/room_types`)
    .put(body)
    .res();

export const putChannelRatePlans = (
  propertyId: number,
  channelId: string,
  body: HBK.Channel.PutRatePlans,
) =>
  api
    .url(`/properties/${propertyId}/channels/${channelId}/rate_plans`)
    .put(body)
    .res();

export const postTomasPairing = (body: HBK.Tomas.PairingBody) =>
  api.url(`/tomas_pairings`).post(body).json<HBK.Tomas.Pairing>();

export const useTomasPairing = (tomasId: string) =>
  useSWR({ url: `/tomas_pairings/${tomasId}` }, (opts) =>
    fetcher<HBK.Tomas.Pairing>(opts),
  );

export const deleteTomasPairing = (tomasId: string) =>
  api.url(`/tomas_pairings/${tomasId}`).delete().res();

export const useTomasConfig = (tomasId: string) =>
  useSWR({ url: `/tomas_pairings/${tomasId}/config` }, (opts) =>
    fetcher<HBK.Tomas.Config>(opts),
  );

export const putTomasConfig = (tomasId: string, body: HBK.Tomas.ConfigBody) =>
  api.url(`/tomas_pairings/${tomasId}/config`).put(body).res();

export const usePropertyReservationPolicies = (
  propertyId: number,
  query: PaginationQuery,
) =>
  useSWR(
    { url: `/properties/${propertyId}/reservation_policies`, query },
    (opts) =>
      fetcher<Paginated<{ reservation_policies: HBK.ReservationPolicy[] }>>(
        opts,
      ),
    {
      keepPreviousData: true,
    },
  );

export const useReservationPolicy = (policyId: string) =>
  useSWR({ url: `/reservation_policies/${policyId}` }, (opts) =>
    fetcher<HBK.ReservationPolicy>(opts),
  );

export const postPropertyReservationPolicy = (
  propertyId: number,
  body: HBK.ReservationPolicyBody,
) =>
  api
    .url(`/properties/${propertyId}/reservation_policies`)
    .post(body)
    .json<HBK.ReservationPolicy>();

export const patchReservationPolicy = (
  policyId: string,
  body: HBK.ReservationPolicyBody,
) =>
  api
    .url(`/reservation_policies/${policyId}`)
    .patch(body)
    .json<HBK.ReservationPolicy>();

export const deleteReservationPolicy = (policyId: string) =>
  api.url(`/reservation_policies/${policyId}`).delete().res();

export const useRatePlans = (propertyId: number, query: PaginationQuery) =>
  useSWR({ url: `/properties/${propertyId}/rate_plans`, query }, (opts) =>
    fetcher<Paginated<{ rate_plans: HBK.RatePlan[] }>>(opts),
  );

export const useRatePlan = (ratePlanId: string) =>
  useSWR({ url: `/rate_plans/${ratePlanId}` }, (opts) =>
    fetcher<HBK.RatePlan>(opts),
  );

export const patchRatePlanTranslations = (
  ratePlanId: string,
  body: HBK.PatchRatePlanTranslationsBody,
) =>
  api
    .url(`/rate_plans/${ratePlanId}/translations`)
    .patch(body)
    .json<HBK.RatePlan>();

export const lockRatePlanTranslations = (ratePlanId: string) =>
  api
    .url(`/rate_plans/${ratePlanId}/translations:lock`)
    .post()
    .json<HBK.RatePlan>();

export const unlockRatePlanTranslations = (ratePlanId: string) =>
  api
    .url(`/rate_plans/${ratePlanId}/translations:unlock`)
    .post()
    .json<HBK.RatePlan>();

export const putRatePlanReservationPolicy = (
  ratePlanId: string,
  body: HBK.ReservationPolicyPairingBody,
) =>
  api
    .url(`/rate_plans/${ratePlanId}/reservation_policy`)
    .put(body)
    .json<HBK.RatePlan>();
