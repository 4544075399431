import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
} from "@headlessui/react";
import classNames from "classnames";
import { ReactNode } from "react";
import { DefaultValues, FieldValues, SubmitHandler } from "react-hook-form";
import Button from "./Button";
import Form, { FormChildrenProps } from "./Form";
import Icon from "./Icon";
import styles from "./Modal.module.css";
import SvgClose from "./icon/Close.svg?react";

interface Props<TFieldValues extends FieldValues> {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: SubmitHandler<TFieldValues>;
  description?: ReactNode;
  submitText?: string;
  showAbort?: boolean;
  defaultValues?: DefaultValues<TFieldValues> | undefined;
  isScrollable?: boolean;
  className?: string;
  children:
    | ReactNode
    | ((useFormProps: FormChildrenProps<TFieldValues>) => ReactNode);
}

const Modal = <TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) => {
  const {
    title,
    description,
    isOpen,
    submitText = "Speichern",
    showAbort = true,
    children,
    isScrollable = false,
    defaultValues,
    className,
    onClose,
    onSubmit,
  } = props;

  return (
    <Transition appear show={isOpen}>
      <Dialog as="div" className={styles.overlay} onClose={onClose}>
        <DialogPanel className={classNames(styles.dialog, className)}>
          <DialogTitle className={styles.title}>
            <div className={styles.header}>
              <Button layout="text" buttonProps={{ onClick: onClose }}>
                <Icon className={styles.cancelIcon} glyph={SvgClose} />
              </Button>
            </div>
            {title}
          </DialogTitle>
          <Description>{description}</Description>
          <Form<TFieldValues>
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            secondaryButton={
              showAbort ? (
                <Button
                  layout="hollow"
                  buttonProps={{
                    onClick: onClose,
                  }}
                >
                  Abbrechen
                </Button>
              ) : undefined
            }
            submitText={submitText}
          >
            {(formProps) => (
              <div
                className={classNames(styles.form, {
                  [styles.isScrollable]: isScrollable,
                })}
              >
                {typeof children === "function"
                  ? children(formProps)
                  : children}
              </div>
            )}
          </Form>
        </DialogPanel>
      </Dialog>
    </Transition>
  );
};

export default Modal;
