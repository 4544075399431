import classNames from "classnames";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";
import styles from "./Settings.module.css";

interface Props {
  settings: HBK.RatePlanSettings;
}

const Settings = ({ settings }: Props) => {
  const areChildrenAllowed = settings.child_occupancy.max !== 0;

  return (
    <Panel
      title="Einstellungen"
      description={
        <>
          Die Einstellungen des Ratenplans werden automatisch mit Ihrem PMS
          synchronisiert. Falls Sie Anpassungen vornehmen möchten, müssen diese
          direkt im PMS erfolgen.
        </>
      }
    >
      <FormField label="Preise pro">
        {i18n.chargeType[settings.charge_type]}
      </FormField>
      <FormField label="Anzahl Nächte pro Aufenthalt">
        {i18n.range(settings.min_stay_arrival, settings.max_stay_arrival)}
      </FormField>
      <FormField label="Anreisetage">
        <DaysOfWeek type="arrival" activeDays={settings.arrival_days} />
      </FormField>
      <FormField label="Abreisetage">
        <DaysOfWeek type="departure" activeDays={settings.departure_days} />
      </FormField>
      <FormField label="Anzahl Erwachsene">
        {i18n.range(settings.adult_occupancy.min, settings.adult_occupancy.max)}
      </FormField>
      <FormField label="Erwachsen ab">
        {i18n.age(settings.adult_occupancy.min_age)}
      </FormField>
      <FormField label="Kinder erlaubt">
        {i18n.boolean(areChildrenAllowed)}
      </FormField>
      {areChildrenAllowed && (
        <>
          <FormField label="Anzahl Kinder">
            {i18n.range(
              settings.child_occupancy.min,
              settings.child_occupancy.max,
            )}
          </FormField>
          <FormField label="Mindestalter Kinder">
            {i18n.age(settings.child_occupancy.min_age)}
          </FormField>
        </>
      )}
    </Panel>
  );
};

interface DaysOfWeekProps {
  type: "arrival" | "departure";
  activeDays: HBK.DaysOfWeek;
}

const DaysOfWeek = ({ type, activeDays }: DaysOfWeekProps) => (
  <div className={styles.daysOfWeek}>
    {i18n.daysOfWeek.map((day, index) => {
      const isActive = activeDays[index];
      return (
        <div
          key={day}
          className={classNames(styles.day, { [styles.active]: isActive })}
          title={
            isActive ? `${i18n[type]} möglich` : `${i18n[type]} nicht möglich`
          }
        >
          {day}
        </div>
      );
    })}
  </div>
);

export default Settings;
