import classNames from "classnames";
import { JSX } from "react";
import styles from "./Photo.module.css";

type PhotoProps = JSX.IntrinsicElements["img"];

const Photo = ({ className, alt = "", ...rest }: PhotoProps) => (
  <div className={classNames(styles.wrapper, className)}>
    <div className={styles.photo}>
      <img alt={alt} {...rest} />
    </div>
  </div>
);

export default Photo;
