import { Fragment, useEffect, useRef } from "react";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import Button from "../../ui/Button";
import FormField from "../../ui/FormField";
import Icon from "../../ui/Icon";
import Input from "../../ui/Input";
import SvgAllInclusive from "../../ui/icon/AllInclusive.svg?react";
import styles from "./CancellationPolicies.module.css";

type Props = UseFormReturn<HBK.ReservationPolicyBody> & {
  isReadonly: boolean;
};

const CancellationPolicies = ({
  isReadonly,
  register,
  control,
  watch,
  setValue,
  formState: { errors },
}: Props) => {
  const [depositPercentage, security, policies] = watch([
    "deposit_percentage",
    "security",
    "cancellation_policies",
  ]);

  const policiesCount = policies.length;

  const { fields, remove, append } = useFieldArray({
    control,
    name: "cancellation_policies",
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...policies[index],
    };
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (policiesCount > 0) {
      setValue(`cancellation_policies.${policiesCount - 1}.end_day`, 0);
      setValue(`cancellation_policies.0.start_day`, null);
    }
  }, [policiesCount]);

  useEffect(() => {
    if (depositPercentage) {
      setValue(`cancellation_policies.0.fee_percentage`, depositPercentage);
    }
  }, [depositPercentage]);

  return (
    <FormField label="Stornobedingungen">
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.title} align="left">
              Tage vor Anreise
            </th>
            <th className={styles.title} align="left">
              Stornogebühr (%)
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {controlledFields.map((field, index) => {
            const policyError = errors.cancellation_policies?.[index];
            const prevIndex = index - 1;
            const prevFeePercentage = policies[prevIndex]?.fee_percentage;

            return (
              <Fragment key={field.id}>
                <tr>
                  <td className={styles.days} align="left">
                    {field.start_day === null ? (
                      <div className={styles.unboundIcon}>
                        <Icon glyph={SvgAllInclusive} className={styles.icon} />
                      </div>
                    ) : (
                      <Input
                        {...register(
                          `cancellation_policies.${index}.start_day`,
                          {
                            valueAsNumber: true,
                            required: true,
                            min: field.end_day + 1,
                          },
                        )}
                        disabled={isReadonly}
                        onChange={(e) =>
                          setValue(
                            `cancellation_policies.${prevIndex}.end_day`,
                            +e.target.value + 1,
                          )
                        }
                        type="number"
                        isInvalid={policyError?.start_day !== undefined}
                        className={styles.input}
                      />
                    )}
                    bis
                    <Input
                      value={field.end_day}
                      disabled={true}
                      className={styles.input}
                      type="number"
                      ref={inputRef}
                    />
                    Tage
                  </td>
                  <td align="left">
                    <Input
                      className={styles.input}
                      {...register(
                        `cancellation_policies.${index}.fee_percentage`,
                        {
                          valueAsNumber: true,
                          required: true,
                          min: prevFeePercentage ? prevFeePercentage + 1 : 0,
                          max: 100,
                        },
                      )}
                      disabled={
                        isReadonly || (index === 0 && security === "deposit")
                      }
                      isInvalid={policyError?.fee_percentage !== undefined}
                      type="number"
                    />
                  </td>
                  <td align="left" className={styles.button}>
                    {index > 0 && (
                      <Button
                        layout="text"
                        buttonProps={{
                          disabled: isReadonly,
                          onClick: () => {
                            remove(index);
                            const startDay = policies[index + 1]?.start_day;
                            setValue(
                              `cancellation_policies.${prevIndex}.end_day`,
                              startDay ? startDay + 1 : 0,
                            );
                          },
                        }}
                      >
                        Entfernen
                      </Button>
                    )}
                  </td>
                </tr>
                {policyError && (
                  <tr>
                    <td colSpan={3}>
                      {policyError.fee_percentage && (
                        <FormField.Error error={policyError.fee_percentage} />
                      )}
                      {policyError.start_day && (
                        <FormField.Error error={policyError.start_day} />
                      )}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      <Button
        layout="hollow"
        buttonProps={{
          disabled: isReadonly || fields.length >= 4,
          onClick: () => {
            const lastIndex = policiesCount - 1;
            const last = policies[lastIndex];
            if (!last) return;

            if (policies[0]?.end_day === 0) {
              setValue(`cancellation_policies.0.end_day`, 31);
            }

            if (last.start_day !== null) {
              setValue(
                `cancellation_policies.${lastIndex}.end_day`,
                last.start_day - 1,
              );
            }

            append({
              start_day: last.start_day === null ? 30 : last.start_day - 2,
              end_day: last.end_day - 1,
              fee_percentage: last.fee_percentage + 10,
            });
          },
        }}
      >
        Zeitraum hinzufügen
      </Button>
    </FormField>
  );
};

export default CancellationPolicies;
