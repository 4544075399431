import { use, useEffect } from "react";
import NotificationContext, {
  showErrorNotification,
} from "../context/notificationContext";

const useErrorNotification = (error: unknown, message: string) => {
  const dispatch = use(NotificationContext);
  useEffect(() => {
    if (error) {
      dispatch(showErrorNotification(error, message));
    }
  }, [error]);
};

export default useErrorNotification;
