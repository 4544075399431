import classNames from "classnames";
import { PropsWithChildren, use, useEffect, useState } from "react";
import NotificationContext, {
  hideNotification,
} from "../context/notificationContext";
import Icon from "./Icon";
import styles from "./Notification.module.css";
import SVGClose from "./icon/Close.svg?react";
import SVGError from "./icon/Error.svg?react";
import SVGTaskAlt from "./icon/TaskAlt.svg?react";

type Props = PropsWithChildren<{
  type: "success" | "error";
  text: string;
  delayed?: number;
}>;

const Notification = ({ type, text, delayed = 5000 }: Props) => {
  const [mounted, setMounted] = useState(false);
  const [close, setClose] = useState(false);
  const dispatch = use(NotificationContext);

  useEffect(() => {
    const timeout = setTimeout(() => setMounted(true), 0);
    return () => clearTimeout(timeout);
  });

  useEffect(() => {
    const timeout = setTimeout(() => setClose(true), delayed);
    return () => clearTimeout(timeout);
  });

  return (
    <div
      className={classNames(styles.notification, {
        [styles.notificationSuccess]: type === "success",
        [styles.notificationError]: type === "error",
        [styles.notificationShown]: mounted && !close,
      })}
      onTransitionEnd={() => {
        if (close) dispatch(hideNotification());
      }}
    >
      <Icon
        className={classNames(styles.notificationIcon, {
          [styles.notificationIconSuccess]: type === "success",
          [styles.notificationIconError]: type === "error",
        })}
        glyph={{ error: SVGError, success: SVGTaskAlt }[type]}
      />
      <div className={styles.text}>{text}</div>
      <button
        type="button"
        className={styles.btnClose}
        onClick={() => setClose(true)}
      >
        <Icon className={styles.btnCloseIcon} glyph={SVGClose} />
      </button>
    </div>
  );
};

export default Notification;
