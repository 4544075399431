import { ChangeEvent } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import FormField from "../../ui/FormField";
import InlineFormField from "../../ui/InlineFormField";
import Input from "../../ui/Input";
import Radio from "../../ui/Radio";
import Select from "../../ui/Select";
import ToggleSwitch from "../../ui/ToggleSwitch";
import { range } from "../../utils";
import CancellationPolicies from "./CancellationPolicies";
import styles from "./ReservationPolicyFormFields.module.css";

type Props = UseFormReturn<HBK.ReservationPolicyBody> & {
  isReadonly: boolean;
};

const daysBeforeArrival = range(0, 10);

const ReservationPolicyFormFields = (formProps: Props) => {
  const {
    isReadonly,
    register,
    watch,
    control,
    setValue,
    resetField,
    formState: { errors },
  } = formProps;

  const [creditCardAllowed, bankTransferAllowed, security, policies] = watch([
    "credit_card_allowed",
    "bank_transfer_allowed",
    "security",
    "cancellation_policies",
  ]);

  return (
    <>
      <FormField label="Name" error={errors.name}>
        {({ labelId, required, isInvalid }) => (
          <Input
            id={labelId}
            type="text"
            placeholder="30&nbsp;% Angeld"
            {...register("name", {
              required,
            })}
            isInvalid={isInvalid}
            disabled={isReadonly}
          />
        )}
      </FormField>
      <FormField label="Sicherheit" error={errors.security}>
        {({ required, isInvalid }) =>
          HBK.reservationSecurity.map((c) => (
            <Radio
              key={c}
              label={i18n.reservationPolicy.type[c]}
              {...register("security", {
                required,
                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                  e.target.value === "deposit" &&
                  setValue("deposit_percentage", policies[0]?.fee_percentage),
              })}
              value={c}
              description={i18n.reservationPolicy.typeDescription[c]}
              isInvalid={isInvalid}
              disabled={isReadonly}
            />
          ))
        }
      </FormField>
      {security === "deposit" && (
        <FormField label="Angeld">
          <FormField
            label="Prozentsatz"
            helpText="Die Zahl muss zwischen 5&nbsp;% und 100&nbsp;% liegen und darf kein Komma enthalten."
            error={errors.deposit_percentage}
            direction="column"
          >
            {({ labelId, required }) => (
              <Input
                id={labelId}
                type="number"
                {...register("deposit_percentage", {
                  required,
                  max: 100,
                  min: 5,
                  valueAsNumber: true,
                })}
                inputMode="numeric"
                disabled={isReadonly}
              />
            )}
          </FormField>
          <FormField
            label="Zahlung mittels Kreditkarte"
            error={errors.credit_card_allowed}
            alignItemsRight
            flexible
            noWrap
          >
            {({ labelId }) => (
              <Controller
                name="credit_card_allowed"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <ToggleSwitch
                    labelId={labelId}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      resetField("bank_transfer_days_before_arrival", {
                        defaultValue: 0,
                      });
                    }}
                    disabled={isReadonly}
                  />
                )}
              />
            )}
          </FormField>
          <FormField
            label="Zahlung mittels Banküberweisung"
            error={errors.bank_transfer_allowed}
            alignItemsRight
            flexible
            noWrap
          >
            {({ labelId }) => (
              <Controller
                name="bank_transfer_allowed"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <ToggleSwitch
                    labelId={labelId}
                    checked={field.value}
                    onChange={field.onChange}
                    disabled={isReadonly}
                  />
                )}
              />
            )}
          </FormField>
          {bankTransferAllowed && (
            <InlineFormField
              label="bis zu"
              text="vor Anreise."
              error={errors.bank_transfer_days_before_arrival}
              helpText={
                !creditCardAllowed && (
                  <>
                    Bitte aktivieren Sie{" "}
                    <strong>Zahlung mittels Kreditkarte</strong>, um diese
                    Einstellung freizuschalten.
                  </>
                )
              }
            >
              {({ labelId, required }) => (
                <Select
                  id={labelId}
                  {...register("bank_transfer_days_before_arrival", {
                    required,
                    valueAsNumber: true,
                  })}
                  className={styles.select}
                  disabled={isReadonly || !creditCardAllowed}
                >
                  {daysBeforeArrival.map((age) => (
                    <option key={age} value={age}>
                      {age === 1 ? "1 Tag" : `${age} Tage`}
                    </option>
                  ))}
                </Select>
              )}
            </InlineFormField>
          )}
        </FormField>
      )}
      <CancellationPolicies {...formProps} />
    </>
  );
};

export default ReservationPolicyFormFields;
