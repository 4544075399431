import { useFormContext } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Fieldset from "../../ui/Fieldset";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";
import Select from "../../ui/Select";
import BookingFormFields from "./BookingFormFields";

const PortalForm = () => {
  const { register, control } = useFormContext<HBK.Widget.Portal.Settings>();

  return (
    <>
      <FormField label="Layout" direction="column">
        {({ required, labelId }) => (
          <Select id={labelId} {...register("layout", { required })}>
            {HBK.Widget.Portal.layouts.map((v) => (
              <option key={v} value={v}>
                {i18n.widget.portal.layout[v]}
              </option>
            ))}
          </Select>
        )}
      </FormField>
      <FormField
        label="Google Maps API Key"
        isOptional={true}
        direction="column"
      >
        {({ labelId }) => (
          <Input
            id={labelId}
            type="text"
            {...register("google_maps_api_key", {
              setValueAs: (v: string) => v || null,
            })}
          />
        )}
      </FormField>
      <Fieldset title="Konfiguration der Buchungsstrecke">
        <BookingFormFields.Layout register={register} name="booking.layout" />
        <BookingFormFields.License register={register} name="booking.license" />
        <BookingFormFields.MaxAdults
          register={register}
          name="booking.max_adults"
        />
        <BookingFormFields.MaxChildren
          register={register}
          name="booking.max_children"
        />
        <BookingFormFields.DefaultRoomPriceList
          register={register}
          name="booking.default_room_price_list"
        />
        <BookingFormFields.EnquiryType
          register={register}
          name="booking.enquiry_type"
        />
        <BookingFormFields.ShowDiscounts
          control={control}
          name="booking.show_discounts"
        />
        <BookingFormFields.OverrideDefaultPriceListTitles
          control={control}
          name="booking.override_default_price_list_titles"
        />
      </Fieldset>
    </>
  );
};

export default PortalForm;
