import { JSX, ReactNode, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useFormSteps } from "../../hooks/useFormSteps";
import useToggle from "../../hooks/useToggle";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import LayerModal from "../../ui/LayerModal";
import Modal from "../../ui/Modal";
import Preview from "../../ui/Preview";
import Wizard from "../../ui/Wizard";
import BookingForm from "./BookingForm";
import ColorSchemeForm from "./ColorSchemeForm";
import EnquiryForm from "./EnquiryForm";
import FieldSettings from "./FieldSettings";
import OfferingForm from "./OfferingForm";
import PortalForm from "./PortalForm";
import PriceForm from "./PriceForm";
import QuickEnquiryForm from "./QuickEnquiryForm";
import WeatherForm from "./WeatherForm";
import WidgetInstance from "./WidgetInstance";
import styles from "./WidgetSettings.module.css";

interface Props {
  initialWidget: HBK.Widget;
  propertyId: number;
  onSubmit: (widget: HBK.Widget) => void;
}

const WidgetSettings = ({ initialWidget, propertyId, onSubmit }: Props) => {
  const navigate = useNavigate();
  const formSteps = useFormSteps();
  const modal = useToggle();
  const [widget, setWidget] = useState(initialWidget);
  const [isDirty, setDirty] = useState(false);

  const handleClose = () => (isDirty ? modal.open() : navigate(".."));
  const steps = useMemo(
    () => buildSteps(widget.type, widget.bookable),
    [widget.type],
  );

  return (
    <>
      <LayerModal
        title={steps[formSteps.step]!.title}
        description={`${i18n.widget.type[widget.type]} bearbeiten`}
        steps={`Schritt ${formSteps.step + 1} von ${steps.length}`}
        isOpen={true}
        onClose={handleClose}
      >
        <div className={styles.wrapper}>
          <div className={styles.settings}>
            {steps.map((step, index) => {
              const lastStep = steps.length === index + 1;

              return (
                formSteps.step === index && (
                  <Wizard<HBK.Widget.Settings | HBK.Widget.ColorScheme>
                    key={index}
                    onDirty={setDirty}
                    abortText={formSteps.step !== 0 ? "Zurück" : "Abbrechen"}
                    submitText={!lastStep ? "Weiter" : "Speichern"}
                    defaultValues={!lastStep ? widget.settings : widget.colors}
                    onChange={(values) => {
                      lastStep
                        ? setWidget({
                            ...widget,
                            colors: values as HBK.Widget.ColorScheme,
                          })
                        : setWidget({
                            ...widget,
                            settings: values as HBK.Widget.Settings,
                          });
                    }}
                    onSubmit={() =>
                      lastStep
                        ? (onSubmit(widget), navigate(".."))
                        : formSteps.nextFormStep()
                    }
                    onAbort={() =>
                      formSteps.step !== 0
                        ? formSteps.prevFormStep()
                        : handleClose()
                    }
                    className={styles.form}
                  >
                    <div className={styles.scrollable}>{step.children}</div>
                  </Wizard>
                )
              );
            })}
          </div>
          <Preview
            title="Vorschau"
            disabled={widget.type === "quick_enquiry"}
            className={styles.preview}
          >
            <WidgetInstance widget={widget} propertyId={propertyId} />
          </Preview>
        </div>
      </LayerModal>
      {modal.isOpen && (
        <Modal
          title="Beenden bestätigen"
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSubmit={() => navigate("..")}
          submitText="Beenden"
        >
          Möchten Sie den Vorgang wirklich beenden?
          <br />
          Ihre Änderungen am {i18n.widget.type[widget.type]} werden nicht
          gespeichert.
        </Modal>
      )}
    </>
  );
};

export default WidgetSettings;

interface Step {
  title: string;
  children: ReactNode;
}

const buildSteps = (type: HBK.Widget.Type, bookable: boolean): Step[] => {
  switch (type) {
    case "booking":
    case "enquiry":
    case "quick_enquiry":
    case "room":
    case "special":
    case "portal": {
      const { form, settings } = selectWidgetSettingsForm(type, bookable);
      return [
        {
          title: "Allgemeine Einstellungen anpassen",
          children: form,
        },
        {
          title: "Gastfelder anpassen",
          children: settings,
        },
        {
          title: "Farben anpassen",
          children: <ColorSchemeForm showSeparator={type !== "booking"} />,
        },
      ];
    }
    case "prices":
    case "weather":
      return [
        {
          title: "Allgemeine Einstellungen anpassen",
          children: selectWidgetSettingsForm(type, bookable).form,
        },
        {
          title: "Farben anpassen",
          children: <ColorSchemeForm showSeparator={true} />,
        },
      ];
  }
};

const selectWidgetSettingsForm = (
  type: HBK.Widget.Type,
  bookable: boolean,
): { form: JSX.Element; settings?: JSX.Element } => {
  switch (type) {
    case "booking":
      return {
        form: <BookingForm />,
        settings: (
          <FieldSettings.Default
            fields={[
              "gender",
              "phone",
              "street",
              "zipcode",
              "city",
              "country",
              "note",
            ]}
          />
        ),
      };
    case "enquiry":
      return {
        form: <EnquiryForm />,
        settings: (
          <FieldSettings.Default
            fields={[
              "stay",
              "occupancies",
              "gender",
              "phone",
              "street",
              "zipcode",
              "city",
              "country",
              "note",
              "newsletter",
            ]}
          />
        ),
      };
    case "prices":
      return { form: <PriceForm /> };
    case "quick_enquiry":
      return {
        form: <QuickEnquiryForm />,
        settings: (
          <FieldSettings.Default fields={["stay", "guests", "gender"]} />
        ),
      };
    case "room":
    case "special":
      return {
        form: <OfferingForm type={type} bookable={bookable} />,
        settings: (
          <FieldSettings.Offering
            fields={[
              "stay",
              "occupancies",
              "gender",
              "phone",
              "street",
              "zipcode",
              "city",
              "country",
              "note",
              "newsletter",
            ]}
          />
        ),
      };
    case "weather":
      return { form: <WeatherForm /> };
    case "portal":
      return {
        form: <PortalForm />,
        settings: (
          <FieldSettings.Portal
            fields={[
              "gender",
              "phone",
              "street",
              "zipcode",
              "city",
              "country",
              "note",
            ]}
          />
        ),
      };
  }
};
