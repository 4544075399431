import { ReactNode } from "react";
import {
  DefaultValues,
  FieldValues,
  Resolver,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import Button from "./Button";
import styles from "./Form.module.css";

export type FormChildrenProps<TFieldValues extends FieldValues> =
  UseFormReturn<TFieldValues> & {
    disabled?: boolean;
  };

interface Props<TFieldValues extends FieldValues> {
  resolver?: Resolver<TFieldValues, object> | undefined;
  disabled?: boolean | undefined;
  secondaryButton?: ReactNode;
  onSubmit?: SubmitHandler<TFieldValues> | undefined;
  submitText?: ReactNode;
  defaultValues?: DefaultValues<TFieldValues> | undefined;
  values?: TFieldValues;
  children:
    | ReactNode
    | ((useFormProps: FormChildrenProps<TFieldValues>) => ReactNode);
}

const Form = <TFieldValues extends FieldValues>({
  children,
  disabled = false,
  secondaryButton,
  submitText = "Speichern",
  onSubmit,
  resolver,
  defaultValues,
  values,
}: Props<TFieldValues>) => {
  // TODO: Find a cleaner way to construct this object with these optional,
  // non-undefined properties.
  const rest: {
    resolver?: Resolver<TFieldValues, object>;
    values?: TFieldValues;
    defaultValues?: DefaultValues<TFieldValues>;
  } = {};
  resolver && (rest.resolver = resolver);
  values && (rest.values = values);
  defaultValues && (rest.defaultValues = defaultValues);

  const useFormProps = useForm<TFieldValues>({
    ...rest,
    mode: "onBlur",
  });
  const { isSubmitting } = useFormProps.formState;

  return (
    <form
      onSubmit={(e) => {
        if (onSubmit) {
          e.stopPropagation();
          useFormProps.handleSubmit(onSubmit)(e);
        }
      }}
    >
      {typeof children === "function"
        ? children({ ...useFormProps, disabled })
        : children}
      {onSubmit && (
        <div className={styles.submitBar}>
          {secondaryButton}
          <Button
            buttonProps={{
              type: "submit",
              disabled: disabled || isSubmitting,
            }}
          >
            {submitText}
          </Button>
        </div>
      )}
    </form>
  );
};

export default Form;
