import classNames from "classnames";
import leaflet from "leaflet";
import { ReactNode, useState } from "react";
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { HBK, usePlaces } from "../../../http/dashboardApi";
import i18n from "../../../i18n";
import Button from "../../../ui/Button";
import Combobox from "../../../ui/Combobox";
import FormField from "../../../ui/FormField";
import FormFieldTranslations from "../../../ui/FormFieldTranslations";
import Input from "../../../ui/Input";
import LeafletMap from "../../../ui/LeafletMap";
import LocationPin, { LocationPinInfo } from "../../../ui/LocationPin";
import Radio from "../../../ui/Radio";
import {
  formatClassification,
  getLeafletLatLng,
  propertyRatings,
} from "../../../utils";
import PropertyRating from "../../property/PropertyRating";
import { NewPropertyFormData } from "./NewProperty";
import styles from "./NewPropertyFormFields.module.css";

interface Props {
  children: (props: {
    register: UseFormRegister<NewPropertyFormData>;
    control: Control<NewPropertyFormData>;
    errors: FieldErrors<NewPropertyFormData>;
    watch: UseFormWatch<NewPropertyFormData>;
    setValue: UseFormSetValue<NewPropertyFormData>;
    getValues: UseFormGetValues<NewPropertyFormData>;
  }) => ReactNode;
}

const NewPropertyFormFields = ({ children }: Props) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useFormContext<NewPropertyFormData>();

  return children({
    register,
    control,
    errors,
    watch,
    setValue,
    getValues,
  });
};

interface FieldProps {
  label: string;
  description?: ReactNode;
  helpText?: ReactNode;
  children:
    | ReactNode
    | ((props: {
        labelId: string;
        isOptional: boolean;
        disabled: boolean;
        required: boolean;
        isInvalid: boolean;
      }) => ReactNode);
  error?: FieldError | undefined;
}

const Field = ({
  label,
  description,
  helpText,
  children,
  error,
}: FieldProps) => (
  <FormField
    label={label}
    description={description}
    helpText={helpText}
    direction="column"
    error={error}
    className={styles.formField}
    rightColumn={{ className: styles.rightColumn }}
  >
    {(props) => (typeof children === "function" ? children(props) : children)}
  </FormField>
);

const Name = () => (
  <NewPropertyFormFields>
    {({ register, errors }) => (
      <Field label="Wie lautet der Name Ihrer Unterkunft?" error={errors.name}>
        {({ required, labelId, isInvalid }) => (
          <Input
            id={labelId}
            type="text"
            {...register("name", {
              required,
            })}
            isInvalid={isInvalid}
          />
        )}
      </Field>
    )}
  </NewPropertyFormFields>
);

const Category = () => (
  <NewPropertyFormFields>
    {({ register, errors }) => (
      <Field
        label="Wählen Sie die Kategorie Ihrer Unterkunft"
        description="Die Kategorie muss mit der Lizenz übereinstimmen."
        error={errors.name}
      >
        {({ required, isInvalid }) =>
          HBK.propertyCategories.map((c) => (
            <Radio
              key={c}
              label={i18n.property.category[c]}
              {...register("category", {
                required,
              })}
              value={c}
              isInvalid={isInvalid}
            />
          ))
        }
      </Field>
    )}
  </NewPropertyFormFields>
);

const Rating = () => (
  <NewPropertyFormFields>
    {({ register, watch, errors }) => (
      <Field
        label="Welche Klassifizierung hat Ihre Unterkunft?"
        description="Die Klassifizierung muss mit der Lizenz übereinstimmen."
        error={errors.category}
      >
        {({ required, isInvalid }) =>
          propertyRatings.map(([rating, isSuperior]) => {
            const value = formatClassification(rating, isSuperior);
            return (
              <Radio
                key={value}
                label={
                  <PropertyRating
                    category={watch("category")}
                    rating={rating}
                    isSuperior={isSuperior}
                  />
                }
                {...register("classification", {
                  required,
                })}
                value={value}
                isInvalid={isInvalid}
              />
            );
          })
        }
      </Field>
    )}
  </NewPropertyFormFields>
);

const Contact = () => (
  <NewPropertyFormFields>
    {({ register, control, errors }) => {
      const { fields, append, remove } = useFieldArray({
        name: "phone_numbers",
        control,
      });

      return (
        <Field label="Geben Sie Ihre Kontaktinformationen ein">
          <Field label="E-Mail" error={errors.email}>
            {({ labelId, required, isInvalid }) => (
              <Input
                id={labelId}
                type="email"
                placeholder="example@example.com"
                {...register("email", {
                  required,
                  validate: (value) =>
                    isEmail(value) || "Die E-Mail-Adresse ist nicht gültig.",
                })}
                isInvalid={isInvalid}
              />
            )}
          </Field>
          <Field label="Telefonnummer">
            {({ required }) => (
              <>
                {fields.map((field, i) => {
                  const error = errors.phone_numbers?.[i]?.number;
                  return (
                    <div key={field.id} className={styles.phoneNumber}>
                      <div className={styles.phoneNumberTop}>
                        <Input
                          type="tel"
                          placeholder="+39 0471 317840"
                          isInvalid={!!error}
                          {...register(`phone_numbers.${i}.number`, {
                            required,
                            validate: (value) => value.trim() !== "",
                          })}
                        />
                        <Button
                          layout="text"
                          buttonProps={{ onClick: () => remove(i) }}
                        >
                          Entfernen
                        </Button>
                      </div>
                      {error && <FormField.Error error={error} />}
                    </div>
                  );
                })}
                <Button
                  layout="hollow"
                  buttonProps={{
                    onClick: () => append({ number: "" }),
                  }}
                >
                  {fields.length === 0
                    ? "Eine Telefonnummer hinzufügen"
                    : "Eine weitere Telefonnummer hinzufügen"}
                </Button>
              </>
            )}
          </Field>
        </Field>
      );
    }}
  </NewPropertyFormFields>
);

const Address = () => (
  <NewPropertyFormFields>
    {({ register, control, setValue, errors }) => (
      <Field label="Wo befindet sich Ihre Unterkunft?">
        <Field
          label="Ortschaft"
          helpText="z.&nbsp;B. Bozen"
          error={errors.place?.id}
        >
          {({ labelId, required }) => (
            <Controller
              name="place"
              control={control}
              rules={{ required }}
              render={({ field }) => (
                <Combobox.Async
                  labelId={labelId}
                  fetcher={usePlaces}
                  value={field.value}
                  displayValue={(place) => place.full_address}
                  onChange={(v) => {
                    field.onChange(v);
                    if (!v) return;
                    setValue("latitude", v.latitude);
                    setValue("longitude", v.longitude);
                  }}
                  placeholder="Nach Ortschaft suchen…"
                />
              )}
            />
          )}
        </Field>
        <Field label="Straße">
          {({ labelId }) => (
            <FormFieldTranslations
              languages={["de", "it"]}
              errors={errors.street}
              labelId={labelId}
            >
              {({ language, isInvalid, labelId }) => (
                <Input
                  id={labelId}
                  isInvalid={isInvalid}
                  {...register(`street.${language}`, {
                    required: true,
                  })}
                />
              )}
            </FormFieldTranslations>
          )}
        </Field>
      </Field>
    )}
  </NewPropertyFormFields>
);

const initialLocationPinState: PinState = {
  showPin: true,
  showPinInfo: true,
};

interface PinState {
  showPin: boolean;
  showPinInfo: boolean;
}

const Map = () => (
  <NewPropertyFormFields>
    {({ watch, setValue }) => {
      const [place, street, latitude, longitude] = watch([
        "place",
        "street",
        "latitude",
        "longitude",
      ]);

      const [pinState, setPinState] = useState<PinState>(
        initialLocationPinState,
      );
      const [position] = useState<leaflet.LatLng>(
        getLeafletLatLng({ latitude, longitude }),
      );

      return (
        <div className={styles.mapWrapper}>
          <h2 className={styles.label}>
            Ist die Stecknadel an der richtigen Stelle?
          </h2>
          <LeafletMap
            position={position}
            className={styles.mapContainer}
            zoom={18}
            isPositioning={pinState.showPin}
            onMove={() =>
              setPinState({
                ...pinState,
                showPinInfo: false,
              })
            }
            onPositionChange={(latLng) => {
              setValue("latitude", latLng.lat);
              setValue("longitude", latLng.lng);
              setPinState({
                ...pinState,
                showPinInfo: true,
              });
            }}
          />
          <LocationPin
            className={classNames(styles.location, styles.pin)}
            disabled={true}
            onClick={() =>
              setPinState({
                showPin: false,
                showPinInfo: false,
              })
            }
          >
            {`${street.de}, ${place.full_address}`}
          </LocationPin>
          {pinState.showPinInfo && (
            <LocationPinInfo
              className={classNames(styles.location, styles.info)}
            >
              Verschieben Sie die Karte, um die Stecknadel richtig zu
              positionieren
            </LocationPinInfo>
          )}
        </div>
      );
    }}
  </NewPropertyFormFields>
);

NewPropertyFormFields.Name = Name;
NewPropertyFormFields.Category = Category;
NewPropertyFormFields.Rating = Rating;
NewPropertyFormFields.Contact = Contact;
NewPropertyFormFields.Address = Address;
NewPropertyFormFields.Map = Map;

export default NewPropertyFormFields;
