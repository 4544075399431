import { use } from "react";
import styles from "./Invitations.module.css";
import InvitationImage from "./assets/subscribe.svg";
import { useAuth } from "./context/authContext";
import NotificationContext, {
  showErrorNotification,
} from "./context/notificationContext";
import useListFilters from "./hooks/useListFilters";
import {
  acceptInvitation,
  declineInvitation,
  useInvitations,
} from "./http/dashboardApi";
import i18n from "./i18n";
import Page from "./pages/Page";
import Button from "./ui/Button";
import DateTimeField from "./ui/DateTimeField";
import Table from "./ui/Table";

const Invitations = () => {
  const filters = useListFilters();
  const { data, isValidating, mutate } = useInvitations(filters.state);
  const dispatch = use(NotificationContext);
  const auth = useAuth();

  const invitations = data?.invitations ?? [];

  return (
    <Page title="Einladungen" backLinkText={i18n.navigation.backToDashboard}>
      {invitations.length === 0 ? (
        <NoInvitation email={auth.user?.email} />
      ) : (
        <Table
          description={
            <>
              Hier finden Sie alle ausstehenden Einladungen. Sobald Sie die
              Einladung angenommen haben, erhalten Sie Zugang zu dieser
              Anwendung.
            </>
          }
          head={
            <tr>
              <th>Unterkunft</th>
              <th>Gültig bis</th>
              <th />
            </tr>
          }
          filters={filters}
          total={data?.total ?? 0}
          isValidating={isValidating}
          body={() =>
            invitations.map((invitation) => {
              return (
                <tr key={invitation.id}>
                  <td>{invitation.property.name}</td>
                  <td>
                    <DateTimeField dateTime={invitation.expires_at} />
                  </td>
                  <td align="right">
                    <div className={styles.buttons}>
                      <Button
                        layout="hollow"
                        buttonProps={{
                          onClick: () =>
                            declineInvitation(invitation.id)
                              .then(() => {
                                mutate();
                              })
                              .catch((error: unknown) => {
                                dispatch(showErrorNotification(error));
                              }),
                        }}
                      >
                        Ablehnen
                      </Button>
                      <Button
                        buttonProps={{
                          onClick: () =>
                            acceptInvitation(invitation.id)
                              .then(() => {
                                mutate();
                              })
                              .catch((error: unknown) => {
                                dispatch(showErrorNotification(error));
                              }),
                        }}
                      >
                        Akzeptieren
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })
          }
        />
      )}
    </Page>
  );
};

interface NoInvitationProps {
  email: string | undefined;
}

const NoInvitation = ({ email }: NoInvitationProps) => {
  return (
    <div className={styles.noInvitation}>
      <div className={styles.noItems}>
        <h2 className={styles.title}>Es sind keine Einladungen vorhanden.</h2>
        <p className={styles.text}>
          Vergewissern Sie sich, dass Sie eine Einladung per E-Mail erhalten
          haben. Überprüfen Sie auch Ihren Spam-Ordner und stellen Sie sicher,
          dass die einladende Person die E-Mail an die richtige Adresse ({email}
          ) geschickt hat.
        </p>
      </div>
      <img src={InvitationImage} className={styles.image} alt="Invitation" />
    </div>
  );
};

export default Invitations;
