import { use, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../context/notificationContext";
import { useFormSteps } from "../../../hooks/useFormSteps";
import { Admin, postProperty } from "../../../http/adminApi";
import { HBK } from "../../../http/dashboardApi";
import PageModal from "../../../ui/PageModal";
import Wizard from "../../../ui/Wizard";
import {
  formatClassification,
  getClassificationFromString,
} from "../../../utils";
import styles from "./NewProperty.module.css";
import NewPropertyFormFields from "./NewPropertyFormFields";

interface Phone {
  number: string;
}

export type NewPropertyFormData = Omit<
  Admin.CreateProperty,
  "phone_numbers" | "address"
> & {
  phone_numbers: Phone[];
  place: HBK.Place;
  street: HBK.TranslatedField;
  latitude: number;
  longitude: number;
};

const steps = [
  <NewPropertyFormFields.Name key={0} />,
  <NewPropertyFormFields.Category key={1} />,
  <NewPropertyFormFields.Rating key={2} />,
  <NewPropertyFormFields.Contact key={3} />,
  <NewPropertyFormFields.Address key={4} />,
  <NewPropertyFormFields.Map key={5} />,
];

const emptyPlace: HBK.Place = {
  id: "",
  municipality: "",
  subdivision: "",
  postal_code: "",
  full_address: "",
  latitude: 0,
  longitude: 0,
};

interface Props {
  initialProperty: Admin.LTSProperty | undefined;
}

const NewProperty = ({ initialProperty }: Props) => {
  const navigate = useNavigate();
  const formSteps = useFormSteps();
  const [property, setProperty] = useState<NewPropertyFormData | undefined>(
    initialProperty
      ? {
          ...initialProperty,
          classification: formatClassification(
            initialProperty.rating,
            initialProperty.is_superior,
          ),
          phone_numbers: [{ number: initialProperty.phone_number }],
          place: emptyPlace,
          street: initialProperty.address.street,
          latitude: initialProperty.address.latitude,
          longitude: initialProperty.address.longitude,
        }
      : undefined,
  );

  const dispatch = use(NotificationContext);

  const onSubmit = (prop: NewPropertyFormData) => {
    const body: HBK.PropertyBody = {
      ...prop,
      ...getClassificationFromString(prop.classification),
      phone_numbers: prop.phone_numbers.map(({ number }) => number),
      address: {
        place_id: prop.place.id,
        street: prop.street,
        latitude: prop.latitude,
        longitude: prop.longitude,
      },
      guest_interaction: "formal",
      listing_status: "unlisted",
      languages: ["de", "it"],
    };
    postProperty(body)
      .then((res) => {
        navigate(`/dashboard/properties/${res.id}/details/profile`);
        dispatch(
          showSuccessNotification("Die Unterkunft wurde erfolgreich angelegt."),
        );
      })
      .catch((error: unknown) => dispatch(showErrorNotification(error)));
  };

  return (
    <PageModal onClose={() => navigate("..")} isOpen>
      <div className={styles.wrapper}>
        {steps.map((step, index) => {
          const lastStep = steps.length === index + 1;

          return (
            formSteps.step === index && (
              <Wizard<NewPropertyFormData>
                key={index}
                defaultValues={
                  property ?? {
                    // The default value for `phone_numbers` ensures an empty
                    // input field is displayed in the contact form.
                    phone_numbers: [{ number: "" }],
                  }
                }
                progressProps={{
                  value: formSteps.step + 1,
                  max: steps.length,
                }}
                onSubmit={() =>
                  lastStep && property
                    ? (onSubmit(property), navigate(".."))
                    : formSteps.nextFormStep()
                }
                onAbort={() =>
                  formSteps.step !== 0
                    ? formSteps.prevFormStep()
                    : navigate("..")
                }
                onChange={(values) => setProperty({ ...property, ...values })}
                abortText={formSteps.step !== 0 ? "Zurück" : "Abbrechen"}
                submitText={!lastStep ? "Weiter" : "Speichern"}
              >
                <div className={styles.context}>
                  <div className={styles.scrollable}>{step}</div>
                </div>
              </Wizard>
            )
          );
        })}
      </div>
    </PageModal>
  );
};

export default NewProperty;
