import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { PropsWithChildren, useState } from "react";
import { HBK } from "../../http/dashboardApi";
import Photo from "../../ui/Photo";

type Props = PropsWithChildren<{
  photos: HBK.Photo[];
  onDragEnd: (e: DragEndEvent) => HBK.Photo[];
}>;

const Sortable = ({ photos, onDragEnd, children }: Props) => {
  const [activeImage, setActiveImage] = useState<string | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActiveImage(active.data.current?.["url"]);
      }}
      onDragEnd={(e) => (onDragEnd(e), setActiveImage(null))}
      onDragCancel={() => setActiveImage(null)}
    >
      <SortableContext items={photos}>{children}</SortableContext>
      <DragOverlay>{activeImage && <Photo src={activeImage} />}</DragOverlay>
    </DndContext>
  );
};

export default Sortable;
