import leaflet from "leaflet";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useEffect, useRef } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  ZoomControl,
  useMapEvents,
} from "react-leaflet";
import "./LeafletMap.css";

const iconWidth = 24;
const iconHeight = 40;

// Fix leaflet's default icon path problems with webpack
// https://github.com/Leaflet/Leaflet/issues/4968
leaflet.Marker.prototype.options.icon = leaflet.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconRetinaUrl: iconRetina,
  iconSize: [iconWidth, iconHeight],
  iconAnchor: [iconWidth / 2, iconHeight],
});

interface MapProps {
  position: leaflet.LatLng;
  zoom: number;
  className: string;
  isPositioning: boolean;
  onMove: () => void;
  onPositionChange: (latlng: leaflet.LatLng) => void;
}

const LeafletMap = ({
  position,
  zoom,
  className,
  isPositioning,
  onMove,
  onPositionChange,
}: MapProps) => {
  const mapRef = useRef<leaflet.Map>(null);
  // Remove Ukrainian flag
  mapRef.current?.attributionControl.setPrefix("");

  useEffect(() => {
    mapRef.current?.setView(position, isPositioning ? 20 : 15, {
      animate: false,
    });
  }, [position, isPositioning]);

  return (
    <MapContainer
      ref={mapRef}
      className={className}
      zoom={zoom}
      zoomControl={false}
      center={position}
      scrollWheelZoom={false}
      dragging={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {isPositioning ? (
        <MovableMarker
          position={position}
          onPositionChange={onPositionChange}
          onMove={onMove}
        />
      ) : (
        <Marker position={position} draggable={false} />
      )}
      <ZoomControl position="topright" />
    </MapContainer>
  );
};

interface MovableMarkerProps {
  position: leaflet.LatLng;
  onMove: () => void;
  onPositionChange: (latlng: leaflet.LatLng) => void;
}

const MovableMarker = ({
  position,
  onPositionChange,
  onMove,
}: MovableMarkerProps) => {
  const markerRef = useRef<leaflet.Marker>(null);

  const map = useMapEvents({
    drag() {
      markerRef.current?.setLatLng(map.getCenter());
    },
    move() {
      onMove();
    },
    dragend() {
      markerRef.current && onPositionChange(markerRef.current.getLatLng());
    },
  });

  return <Marker position={position} draggable={false} ref={markerRef} />;
};

export default LeafletMap;
