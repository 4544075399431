import { use } from "react";
import { useNavigate } from "react-router";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import { HBK, postRoomType } from "../../http/dashboardApi";
import Page from "../../pages/Page";
import { BackButton } from "../../ui/Button";
import Form from "../../ui/Form";
import RoomTypeFormFields from "./RoomTypeFormFields";

interface Props {
  languages: HBK.Language[];
}

const CreateRoomType = ({ languages }: Props) => {
  const propertyId = usePropertyIdParam();
  const navigate = useNavigate();
  const dispatch = use(NotificationContext);

  return (
    <Page title="Zimmergruppe erstellen">
      <Form<HBK.RoomTypeBody>
        secondaryButton={<BackButton />}
        onSubmit={(values) =>
          postRoomType(propertyId, values)
            .then((res) => {
              navigate(`../${res.id}`);
              dispatch(showSuccessNotification());
            })
            .catch((error: unknown) => dispatch(showErrorNotification(error)))
        }
      >
        {(props) => (
          <RoomTypeFormFields
            {...props}
            languages={languages}
            initialCategory="room"
          />
        )}
      </Form>
    </Page>
  );
};

export default CreateRoomType;
