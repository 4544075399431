import { use } from "react";
import { useNavigate } from "react-router";
import isBIC from "validator/lib/isBIC";
import isIBAN from "validator/lib/isIBAN";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import {
  HBK,
  postPropertyBankAccount as postBankAccount,
} from "../../http/dashboardApi";
import { BackButton } from "../../ui/Button";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";
import Panel from "../../ui/Panel";

const BankAccount = () => {
  const navigate = useNavigate();
  const propertyId = usePropertyIdParam();
  const dispatch = use(NotificationContext);

  return (
    <Panel
      title="Kontodaten"
      description="Werden dem Gast im Buchungsbestätigungsmail angezeigt, wenn die Bezahlung mittels Überweisung erfolgt."
    >
      <Form<HBK.BankAccount>
        secondaryButton={<BackButton />}
        onSubmit={(values) => {
          postBankAccount(propertyId, values)
            .then(() => {
              dispatch(showSuccessNotification());
              navigate("..");
            })
            .catch((error: unknown) => {
              dispatch(showErrorNotification(error));
            });
        }}
      >
        {({ register, formState: { errors } }) => (
          <>
            <FormField
              label="Name der Bank"
              helpText="Bitte geben Sie den vollständigen Namen Ihrer Bank ein."
              error={errors.name}
            >
              {({ labelId, required, isInvalid }) => (
                <Input
                  id={labelId}
                  type="text"
                  {...register("name", {
                    required,
                  })}
                  isInvalid={isInvalid}
                />
              )}
            </FormField>
            <FormField
              label="IBAN"
              helpText="Die IBAN (Internationale Bankkontonummer) finden Sie auf Ihrem Kontoauszug oder in Ihren Kontoinformationen. Es sind keine Leerzeichen erlaubt."
              error={errors.iban}
            >
              {({ labelId, required, isInvalid }) => (
                <Input
                  id={labelId}
                  type="text"
                  placeholder="DE00210501700012345678"
                  {...register("iban", {
                    required,
                    validate: (value) =>
                      isIBAN(value) || "Die IBAN ist nicht gültig.",
                  })}
                  isInvalid={isInvalid}
                />
              )}
            </FormField>
            <FormField
              label="SWIFT/BIC-Code"
              helpText="Der SWIFT- oder BIC-Code ist in der Regel 8 oder 11 Zeichen lang. Sie finden ihn auf Ihrem Kontoauszug oder in Ihren Kontoinformationen."
              isOptional={true}
              error={errors.swift}
            >
              {({ labelId, required, isInvalid }) => (
                <Input
                  id={labelId}
                  type="text"
                  placeholder="AAAABBCC123"
                  {...register("swift", {
                    required,
                    validate: (value) =>
                      isBIC(value) || "Der SWIFT/BIC-Code ist nicht gültig.",
                  })}
                  isInvalid={isInvalid}
                />
              )}
            </FormField>
          </>
        )}
      </Form>
    </Panel>
  );
};

export default BankAccount;
