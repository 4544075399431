import { use } from "react";
import { useNavigate } from "react-router";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import {
  HBK,
  postPropertyWidgets,
  usePropertyWidgets,
} from "../../http/dashboardApi";
import Page from "../../pages/Page";
import Button from "../../ui/Button";
import Form from "../../ui/Form";
import CreateWidgetFormFields from "./CreateWidgetFormFields";

const CreateWidget = () => {
  const propertyId = usePropertyIdParam();
  const filters = useListFilters();
  const navigate = useNavigate();
  const dispatch = use(NotificationContext);
  const { data, mutate } = usePropertyWidgets(propertyId, filters.state);

  return (
    <Page title="Neues Widget">
      <Form<{ type: HBK.Widget.Type }>
        secondaryButton={
          <Button
            layout="hollow"
            buttonProps={{
              onClick: () => navigate(".."),
            }}
          >
            Abbrechen
          </Button>
        }
        onSubmit={(body) => {
          postPropertyWidgets(propertyId, {
            type: body.type,
          })
            .then((widget) => {
              mutate();
              navigate(`../${widget.id}`);
              dispatch(showSuccessNotification());
            })
            .catch((error: unknown) => dispatch(showErrorNotification(error)));
        }}
      >
        {(props) => (
          <CreateWidgetFormFields
            {...props}
            isPropertyWidget={true}
            activeTypes={data?.widgets.map((i) => i.type)}
          />
        )}
      </Form>
    </Page>
  );
};

export default CreateWidget;
