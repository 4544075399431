import { useEffect, useId, useRef } from "react";
import useSearchFilter from "../../hooks/useSearchFilter";
import { useSearchProperties } from "../../http/dashboardApi";
import Input from "../../ui/Input";
import LoadingSpinner from "../../ui/LoadingSpinner";
import Menu from "../../ui/Menu";
import PropertyCard from "../../ui/PropertyCard";
import SvgSearch from "../../ui/icon/Search.svg?react";
import styles from "./PropertySearch.module.css";

const PropertySearch = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const filter = useSearchFilter();
  const inputId = useId();

  const { data: results, isValidating } = useSearchProperties({
    query: filter.query,
    signal: filter.signal,
  });

  useEffect(() => {
    queueMicrotask(() => {
      inputRef.current?.focus();
    });
  }, []);

  return (
    <>
      <div className={styles.searchField} role="search">
        <label className={styles.leading} htmlFor={inputId}>
          <LoadingSpinner size="small" isLoading={isValidating} delayed={100}>
            <SvgSearch className={styles.searchIcon} />
          </LoadingSpinner>
        </label>
        <Input
          ref={inputRef}
          id={inputId}
          type="search"
          onKeyDown={(e) => {
            // Prevent menu from closing when the spacebar is pressed
            if (e.code === "Space") e.stopPropagation();
          }}
          onChange={(e) => filter.setSearchTerm(e.currentTarget.value)}
          placeholder="Suche"
          className={styles.searchInput}
          autoComplete="off"
          value={filter.searchTerm}
        />
      </div>
      {results &&
        filter.searchTerm !== "" &&
        (results.length === 0 ? (
          <div className={styles.noResults}>
            <strong>Keine Suchergebnisse gefunden</strong>
          </div>
        ) : (
          <ul className={styles.options}>
            {results.map((p) => (
              <li key={p.id} className={styles.option}>
                <Menu.Link to={`/dashboard/properties/${p.id}`}>
                  <PropertyCard
                    name={p.name}
                    address={p.address.place.full_address}
                  />
                </Menu.Link>
              </li>
            ))}
          </ul>
        ))}
    </>
  );
};

export default PropertySearch;
