import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Link, To } from "react-router-dom";
import { Glyph } from "../types";
import styles from "./Controls.module.css";
import Icon from "./Icon";
import SvgDelete from "./icon/Delete.svg?react";
import SvgMore from "./icon/More.svg?react";

type ControlsProps = PropsWithChildren<{
  className?: string;
}>;

const Controls = ({ className, children }: ControlsProps) => {
  return (
    <Menu as="div" className={className}>
      <MenuButton className={styles.btn}>
        <Icon glyph={SvgMore} />
      </MenuButton>
      <MenuItems anchor="bottom end" modal={false} className={styles.items}>
        {children}
      </MenuItems>
    </Menu>
  );
};

type GroupProps = PropsWithChildren;

const Group = ({ children }: GroupProps) => (
  <div className={styles.group}>{children}</div>
);
Group.displayName = "Controls.Group";
Controls.Group = Group;

type ItemType = "danger";

type ItemProps = PropsWithChildren<{
  onClick?: () => void;
  glyph?: Glyph;
  disabled?: boolean;
  type?: ItemType;
  title?: string | undefined;
}>;

const Item = ({
  children,
  disabled = false,
  onClick,
  glyph,
  type,
  title,
}: ItemProps) => (
  <MenuItem disabled={disabled}>
    {({ focus }) => (
      <button
        type="button"
        onClick={onClick}
        title={title}
        className={classNames(styles.item, {
          [styles.focused]: focus,
          [styles.danger]: type === "danger",
          [styles.disabled]: disabled,
        })}
      >
        {glyph && <Icon glyph={glyph} className={styles.itemIcon} />} {children}
      </button>
    )}
  </MenuItem>
);
Item.displayName = "Controls.Item";
Controls.Item = Item;

type LinkItemProps = PropsWithChildren<{
  to: To;
  glyph?: Glyph;
}>;

const LinkItem = ({ to, children, glyph }: LinkItemProps) => (
  <MenuItem>
    {({ focus }) => (
      <Link
        className={classNames(styles.item, {
          [styles.focused]: focus,
        })}
        to={to}
      >
        {glyph && <Icon glyph={glyph} className={styles.itemIcon} />} {children}
      </Link>
    )}
  </MenuItem>
);
LinkItem.displayName = "Controls.Link";
Controls.Link = LinkItem;

const Delete = (props: ItemProps) => (
  <Controls.Item glyph={SvgDelete} type="danger" {...props}>
    Löschen
  </Controls.Item>
);
Delete.displayName = "Controls.Delete";
Controls.Delete = Delete;

export default Controls;
