import { use } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import {
  deleteReservationPolicy,
  postPropertyReservationPolicy,
  usePropertyReservationPolicies,
} from "../../http/dashboardApi";
import Badge from "../../ui/Badge";
import { AddButton } from "../../ui/Button";
import Confirm from "../../ui/Confirm";
import Controls from "../../ui/Controls";
import Panel from "../../ui/Panel";
import Table from "../../ui/Table";
import SvgContentCopy from "../../ui/icon/ContentCopy.svg?react";

const ReservationPolicies = () => {
  const propertyId = usePropertyIdParam();
  const filters = useListFilters();
  const navigate = useNavigate();
  const dispatch = use(NotificationContext);

  const { data, isValidating, mutate } = usePropertyReservationPolicies(
    propertyId,
    filters.state,
  );

  const reservationPolicies = data?.reservation_policies ?? [];
  const maxPoliciesReached =
    reservationPolicies.filter((r) => !r.is_readonly).length >= 3;

  return (
    <Panel
      title="Reservierungsbedingungen"
      description="Sie haben die Möglichkeit bis zu drei eigene Reservierungsbedingungen zu erstellen."
      controls={
        <AddButton
          buttonProps={{
            onClick: () => navigate("create"),
            disabled: maxPoliciesReached,
          }}
        >
          Neue Reservierungsbedingung
        </AddButton>
      }
    >
      <Table
        head={
          <tr>
            <th>Name</th>
            <th>Angeld</th>
            <th>Preislisten</th>
            <th />
            <th />
          </tr>
        }
        isValidating={isValidating}
        total={data?.total ?? 0}
        filters={filters}
        body={() =>
          reservationPolicies.map((r) => {
            return (
              <tr key={r.id}>
                <td>
                  <Link to={r.id}>{r.name}</Link>
                </td>
                <td>{r.deposit_percentage ?? 0}&nbsp;%</td>
                <td>0</td>
                <td>
                  {r.is_readonly ? (
                    <Badge.InfoModal
                      modal={{
                        title: "Schreibgeschützte Reservierungsbedingung",
                        body: "Dies ist eine vordefinierte Reservierungsbedingung und kann nicht verändert oder gelöscht werden.",
                      }}
                    >
                      Schreibgeschützt
                    </Badge.InfoModal>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  <Confirm
                    title="Reservierungsbedingung löschen"
                    description={
                      <p>
                        Möchten Sie die Reservierungsbedingung wirklich löschen?
                      </p>
                    }
                    onConfirm={() =>
                      deleteReservationPolicy(r.id).then(() => mutate())
                    }
                  >
                    {({ open }) => (
                      <Controls>
                        <Controls.Group>
                          <Controls.Link to={r.id}>Zeige Details</Controls.Link>
                        </Controls.Group>
                        <Controls.Group>
                          <Controls.Item
                            disabled={maxPoliciesReached}
                            glyph={SvgContentCopy}
                            onClick={() =>
                              postPropertyReservationPolicy(propertyId, {
                                ...r,
                                name: `${r.name} - Kopie`,
                              })
                                .then(() => {
                                  mutate();
                                  dispatch(
                                    showSuccessNotification(
                                      "Die Reservierungsbedingung wurde erfolgreich dupliziert.",
                                    ),
                                  );
                                })
                                .catch((error: unknown) =>
                                  dispatch(showErrorNotification(error)),
                                )
                            }
                          >
                            Duplizieren
                          </Controls.Item>
                        </Controls.Group>
                        <Controls.Group>
                          <Controls.Delete
                            onClick={open}
                            disabled={r.is_readonly}
                          />
                        </Controls.Group>
                      </Controls>
                    )}
                  </Confirm>
                </td>
              </tr>
            );
          })
        }
      />
    </Panel>
  );
};

export default ReservationPolicies;
