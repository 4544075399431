import { ReactNode } from "react";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";
import Page from "../../../pages/Page";
import { Glyph } from "../../../types";
import Card from "../../../ui/Card";
import Icon from "../../../ui/Icon";
import SvgAddProperty from "../../../ui/icon/AddProperty.svg?react";
import SvgArrowForward from "../../../ui/icon/ArrowForward.svg?react";
import SvgSyncProperty from "../../../ui/icon/SyncProperty.svg?react";

interface CreatePropertyOption {
  type: "new" | "lts-import";
  description: ReactNode;
  icon: Glyph;
}

const createPropertyOptions: CreatePropertyOption[] = [
  {
    type: "new",
    description: "Eine neue Unterkunft erstellen.",
    icon: SvgAddProperty,
  },
  {
    type: "lts-import",
    description: (
      <>
        Eine bestehende Unterkunft aus dem{" "}
        <a
          href="https://tic.lts.it/ticweb/index.phtml"
          target="_blank"
          rel="noreferrer"
        >
          LTS TIC-Web
        </a>{" "}
        importieren.
      </>
    ),
    icon: SvgSyncProperty,
  },
];

const CreateProperty = () => (
  <Page title="Neue Unterkunft" backLinkText={i18n.navigation.backToOverview}>
    {createPropertyOptions.map((option) => (
      <Card
        key={option.type}
        link={option.type}
        title={i18n.property.create.type[option.type]}
        glyph={option.icon}
        controls={
          <Link to={option.type}>
            <Icon glyph={SvgArrowForward} />
          </Link>
        }
      >
        {option.description}
      </Card>
    ))}
  </Page>
);

export default CreateProperty;
