import classNames from "classnames";
import { JSX, Ref } from "react";
import inputStyles from "./Input.module.css";
import styles from "./Textarea.module.css";

type Props = JSX.IntrinsicElements["textarea"] & {
  isInvalid?: boolean;
  ref: Ref<HTMLTextAreaElement>;
};

const Textarea = ({ ref, isInvalid, ...props }: Props) => {
  return (
    <textarea
      ref={ref}
      {...props}
      className={classNames(
        inputStyles.input,
        styles.textarea,
        props.className,
        {
          [inputStyles.invalid]: isInvalid,
        },
      )}
    />
  );
};

export default Textarea;
