import { Upload } from "../../hooks/usePhotoUpload";
import { createPhotoUploadURL, HBK, postPhoto } from "../../http/dashboardApi";
import FormField from "../../ui/FormField";
import Photo from "../../ui/Photo";

interface Props {
  propertyId: number;
  roomTypeId: string | undefined;
  ratePlanId: string | undefined;
  upload: Upload;
  onStart: () => void;
  onSuccess: (photos: HBK.Photo[]) => void;
  onError: (error: unknown) => void;
}

export const UploadPhoto = ({
  propertyId,
  roomTypeId,
  ratePlanId,
  upload: { objectURL, file, error, isUploading },
  onStart,
  onSuccess,
  onError,
}: Props) => {
  return (
    <div>
      <Photo
        src={objectURL}
        alt={file.name}
        onLoad={async () => {
          if (error || isUploading) return;

          onStart();

          try {
            const policy = await createPhotoUploadURL(propertyId);
            const formData = new FormData();

            for (const [field, value] of Object.entries(policy.fields)) {
              formData.append(field, value);
            }

            // Must be the last child of the form
            // https://stackoverflow.com/questions/16378713/unable-to-upload-file-to-google-cloud-storage-using-post-method
            formData.append("file", file);

            await fetch(policy.url, {
              method: "POST",
              body: formData,
            });

            const res = await postPhoto(propertyId, {
              id: policy.object_id,
              room_type_id: roomTypeId,
              rate_plan_id: ratePlanId,
            });

            onSuccess(res.photos);
          } catch (error) {
            if (error instanceof Error) {
              console.error(error);
              onError(error);
            }
          }
        }}
      />
      {error && <FormField.Error error={error} />}
    </div>
  );
};
