import classNames from "classnames";
import { JSX, Ref } from "react";
import styles from "./Input.module.css";

export type Props = JSX.IntrinsicElements["input"] & {
  isInvalid?: boolean;
  ref?: Ref<HTMLInputElement>;
};

const Input = ({ ref, isInvalid, ...props }: Props) => {
  const { type = "text" } = props;
  return (
    <input
      ref={ref}
      {...props}
      type={type}
      className={classNames(styles.input, props.className, {
        [styles.invalid]: isInvalid,
      })}
    />
  );
};

export default Input;
