import classNames from "classnames";
import { use } from "react";
import SidebarContext from "../context/sidebarContext";
import styles from "./Hamburger.module.css";

const HamburgerButton = () => {
  const { isOpen, setOpen } = use(SidebarContext);

  return (
    <button
      type="button"
      className={classNames(styles.button, {
        [styles.buttonOpen]: isOpen,
      })}
      onClick={() => setOpen(!isOpen)}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

export default HamburgerButton;
