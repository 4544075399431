import { use } from "react";
import { useFieldArray } from "react-hook-form";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { putRooms, useRooms } from "../../http/dashboardApi";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";
import Selected from "../../ui/Selected";
import AddRoomsModal from "./AddRoomsModal";

interface RoomsProps {
  roomTypeId: string;
}

interface FormRoom {
  code: string;
}

const Rooms = ({ roomTypeId }: RoomsProps) => {
  const dispatch = use(NotificationContext);
  const { data, mutate } = useRooms(roomTypeId);

  return (
    <Panel title="Zimmer">
      <Form<{ rooms: FormRoom[] }>
        values={{
          rooms: data?.rooms.map(({ code }) => ({ code })) ?? [],
        }}
        onSubmit={({ rooms }) => {
          putRooms(
            roomTypeId,
            rooms.map((r) => r.code),
          )
            .then((res) => {
              mutate(res, false);
              dispatch(showSuccessNotification());
            })
            .catch((error: unknown) => dispatch(showErrorNotification(error)));
        }}
      >
        {({ control }) => {
          const { fields, append, remove } = useFieldArray({
            control,
            name: "rooms",
          });

          return (
            <FormField label="Zimmernummern">
              <Selected.List
                fields={fields}
                controls={
                  <AddRoomsModal
                    title={
                      fields.length > 0
                        ? "Eine weitere Zimmernummer hinzufügen"
                        : "Eine Zimmernummer hinzufügen"
                    }
                    onSubmit={(codes) =>
                      append(codes.map((code) => ({ code })))
                    }
                  />
                }
              >
                {({ field, index }) => (
                  <Selected.Item
                    key={field.id}
                    name={field.code}
                    onDelete={() => remove(index)}
                  />
                )}
              </Selected.List>
            </FormField>
          );
        }}
      </Form>
    </Panel>
  );
};

export default Rooms;
