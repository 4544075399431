import { use } from "react";
import { useNavigate } from "react-router-dom";
import isNumeric from "validator/lib/isNumeric";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import {
  HBK,
  postTomasPairing,
  tryParseErrorResponse,
} from "../../http/dashboardApi";
import Page from "../../pages/Page";
import { BackButton } from "../../ui/Button";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";

interface Props {
  propertyId: number;
}

const CreateTomasPairing = ({ propertyId }: Props) => {
  const navigate = useNavigate();
  const dispatch = use(NotificationContext);

  return (
    <Page
      title="TOMAS-Koppelung erstellen"
      description="Stellen Sie sicher, dass im Easychannel der AlpineBits HGV Channel für diese Unterkunft aktiviert wurde."
    >
      <Form<{
        tomas: Pick<
          HBK.Tomas.PairingBody,
          "api_key" | "hotel_id" | "id" | "user_id"
        >;
      }>
        onSubmit={(values) => {
          postTomasPairing({
            ...values.tomas,
            property: { id: propertyId },
          })
            .then((res) => {
              navigate(res.id, { replace: false });
              dispatch(showSuccessNotification());
            })
            .catch((err: unknown) => {
              const error = tryParseErrorResponse(err)?.error;
              dispatch(showErrorNotification(error, error?.message));
            });
        }}
        secondaryButton={<BackButton />}
      >
        {({ register, formState: { errors } }) => {
          return (
            <>
              <FormField label="Client ID" error={errors.tomas?.id}>
                {({ labelId, required, isInvalid }) => (
                  <Input
                    id={labelId}
                    type="text"
                    placeholder="TRVXXXXXXXXXXXXXXXXX"
                    {...register("tomas.id", {
                      required,
                    })}
                    isInvalid={isInvalid}
                  />
                )}
              </FormField>
              <FormField
                label="User ID"
                description="myhotelshop"
                error={errors.tomas?.user_id}
              >
                {({ labelId, required, isInvalid }) => (
                  <Input
                    id={labelId}
                    type="text"
                    placeholder="0000"
                    {...register("tomas.user_id", {
                      required,
                      valueAsNumber: true,
                      validate: (value) =>
                        isNumeric(String(value)) ||
                        "Der eingegebene Wert ist keine Zahl",
                    })}
                    isInvalid={isInvalid}
                  />
                )}
              </FormField>
              <FormField
                label="Hotel ID"
                description="myhotelshop"
                error={errors.tomas?.hotel_id}
              >
                {({ labelId, required, isInvalid }) => (
                  <Input
                    id={labelId}
                    type="text"
                    placeholder="0000"
                    {...register("tomas.hotel_id", {
                      required,
                      valueAsNumber: true,
                      validate: (value) =>
                        isNumeric(String(value)) ||
                        "Der eingegebene Wert ist keine Zahl",
                    })}
                    isInvalid={isInvalid}
                  />
                )}
              </FormField>
              <FormField
                label="API Key"
                description="myhotelshop"
                error={errors.tomas?.api_key}
              >
                {({ labelId, required, isInvalid }) => (
                  <Input
                    id={labelId}
                    type="text"
                    placeholder="xxxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx"
                    {...register("tomas.api_key", {
                      required,
                    })}
                    isInvalid={isInvalid}
                  />
                )}
              </FormField>
            </>
          );
        }}
      </Form>
    </Page>
  );
};

export default CreateTomasPairing;
