import { ReactNode, use } from "react";
import { Link, useNavigate } from "react-router-dom";
import js from "react-syntax-highlighter/dist/esm/languages/prism/javascript";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/prism-light";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import NotificationContext, {
  showSuccessNotification,
} from "../../context/notificationContext";
import usePermissions from "../../hooks/usePermissions";
import { HBK } from "../../http/dashboardApi";
import i18n, { getWidgetLayout } from "../../i18n";
import Page from "../../pages/Page";
import Button from "../../ui/Button";
import ColorScheme from "../../ui/ColorScheme";
import DateTimeField from "../../ui/DateTimeField";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";
import ShortInfo from "../../ui/ShortInfo";
import SvgContentCopy from "../../ui/icon/ContentCopy.svg?react";
import SvgEdit from "../../ui/icon/Edit.svg?react";
import { isPortalWidget } from "./Widget";
import WidgetInstance from "./WidgetInstance";
import styles from "./WidgetOverview.module.css";

SyntaxHighlighter.registerLanguage("javascript", js);

interface Props {
  widget: HBK.WidgetResponse;
  portal: HBK.WidgetPortal | undefined;
  property: HBK.WidgetProperty | undefined;
  isLoading: boolean;
  controls: ReactNode;
}

const WidgetOverview = ({ widget, property, controls, isLoading }: Props) => {
  const navigate = useNavigate();
  const dispatch = use(NotificationContext);
  const canWrite = usePermissions(HBK.Permission.WidgetWrite);

  return (
    <Page
      title={i18n.widget.type[widget.type]}
      shortInfo={
        <>
          {isPortalWidget(widget) && (
            <ShortInfo title="Portal">
              <Link to={`/admin/portals/${widget.portal.id}`}>
                {widget.portal.name}
              </Link>
            </ShortInfo>
          )}
          <ShortInfo title="ID">{widget.id}</ShortInfo>
        </>
      }
      backLinkText={i18n.navigation.backToOverview}
      isLoading={isLoading}
      controls={
        canWrite && (
          <Button
            layout="hollow"
            buttonProps={{
              onClick: () => navigate("edit"),
              disabled: !property,
            }}
            glyph={SvgEdit}
          >
            {i18n.widget.type[widget.type]} bearbeiten
          </Button>
        )
      }
    >
      <div className={styles.page}>
        <div className={styles.header}>
          <Panel
            title="Quelltext"
            description="Kopieren Sie diesen Quelltext und fügen Sie ihn auf Ihrer Website ein."
            controls={
              <Button
                size="small"
                layout="hollow"
                glyph={SvgContentCopy}
                buttonProps={{
                  title: "Quelltext in die Zwischenablage kopieren.",
                  onClick: () => (
                    navigator.clipboard.writeText(
                      getWidgetSourceCode({
                        widgetId: widget.id,
                        type: widget.type,
                        propertyId: property?.id,
                      }),
                    ),
                    dispatch(
                      showSuccessNotification(
                        "Der Quelltext wurde in die Zwischenablage kopiert.",
                      ),
                    )
                  ),
                }}
              >
                Quelltext kopieren
              </Button>
            }
            footer={
              <>
                Sie benötigen Unterstützung bei der Integration in Ihre Seite?{" "}
                <a
                  href={getDocsURL({
                    id: widget.id,
                    type: widget.type,
                    propertyId: property?.id,
                  }).toString()}
                  target="_blank"
                  rel="noreferrer"
                >
                  Lesen Sie hier unsere Dokumentation.
                </a>
              </>
            }
          >
            <div className={styles.code}>
              <SyntaxHighlighter
                language="javascript"
                style={vs}
                customStyle={{
                  background: "none",
                  border: "none",
                  margin: 0,
                }}
                showLineNumbers={true}
              >
                {getWidgetSourceCode({
                  widgetId: widget.id,
                  type: widget.type,
                  propertyId: property?.id,
                })}
              </SyntaxHighlighter>
            </div>
          </Panel>
          <Panel title="Details" className={styles.details} controls={controls}>
            <FormField label="Layout">
              {getWidgetLayout(widget.type, widget.settings.layout)}
            </FormField>
            <FormField label="Erstellt am">
              <DateTimeField dateTime={widget.created_at} />
            </FormField>
            <FormField label="Eigene Farben">
              {i18n.boolean(!widget.default_colors)}
            </FormField>
            <FormField label="Farbschema">
              <ColorScheme
                colors={[
                  ...Object.values(widget.colors.main).map((i) => i ?? ""),
                  ...Object.values(widget.colors.primary),
                  ...Object.values(widget.colors.secondary),
                ]}
              />
            </FormField>
          </Panel>
        </div>
        {property && (
          <Panel title="Vorschau" className={styles.widget}>
            <WidgetInstance widget={widget} propertyId={property.id} />
          </Panel>
        )}
      </div>
    </Page>
  );
};

export default WidgetOverview;

const getWidgetSourceCode = ({
  widgetId,
  type,
  propertyId,
}: {
  widgetId: string;
  type: HBK.Widget.Type;
  propertyId: number | undefined;
}): string => {
  switch (type) {
    case "booking":
      return BookingSourceCode(widgetId, propertyId);
    case "enquiry":
      return EnquirySourceCode(widgetId, propertyId);
    case "prices":
      return PriceSourceCode(widgetId, propertyId);
    case "quick_enquiry":
      return QuickEnquirySourceCode(widgetId, propertyId);
    case "room":
      return RoomsSourceCode(widgetId, propertyId);
    case "special":
      return SpecialsSourceCode(widgetId, propertyId);
    case "weather":
      return WeatherSourceCode(widgetId, propertyId);
    case "portal":
      return PortalSourceCode(widgetId);
  }
};

const BookingSourceCode = (
  widgetId: string,
  propertyId?: number,
) => `<div id="Bs-BookingWidget"></div>
<script id="booking-suedtirol-js" src="https://widget.bookingsuedtirol.com/v2/bundle.js" defer></script>
<script>
    document
    .querySelector("#booking-suedtirol-js")
    .addEventListener("load", () => {
        BookingSüdtirol.Widgets.Booking("#Bs-BookingWidget", {
            id: "${widgetId}",
            propertyId: ${propertyId},
            lang: "de",
            privacyURL: "/path/to/privacy/page",
            termsURL: "/path/to/terms/page",
            onBookingSuccess: (reservation) => {
                // Called after successful booking
            },
            onEnquirySuccess: (reservation) => {
                // Called after successful enquiry
            },
        });
    });
</script>`;

const EnquirySourceCode = (
  widgetId: string,
  propertyId?: number,
) => `<div id="Bs-EnquiryWidget"></div>
<script id="booking-suedtirol-js" src="https://widget.bookingsuedtirol.com/v2/bundle.js" defer></script>
<script>
    document
    .querySelector("#booking-suedtirol-js")
    .addEventListener("load", () => {
        BookingSüdtirol.Widgets.Enquiry("#Bs-EnquiryWidget", {
            id: "${widgetId}",
            propertyId: ${propertyId},
            lang: "de",
            privacyURL: "/path/to/privacy/page",
            onEnquirySuccess: (reservation) => {
                // Called after successful enquiry
            },
        });
    });
</script>`;

const QuickEnquirySourceCode = (
  widgetId: string,
  propertyId?: number,
) => `<div id="Bs-QuickEnquiryWidget"></div>
<script id="booking-suedtirol-js" src="https://widget.bookingsuedtirol.com/v2/bundle.js" defer></script>
<script>
    document
    .querySelector("#booking-suedtirol-js")
    .addEventListener("load", () => {
        BookingSüdtirol.Widgets.QuickEnquiry("#Bs-QuickEnquiryWidget", {
            id: "${widgetId}",
            propertyId: ${propertyId},
            lang: "de",
            privacyURL: "/path/to/privacy/page",
            onEnquirySuccess: (reservation) => {
                // Called after successful enquiry
            },
        });
    });
</script>`;

const PriceSourceCode = (
  widgetId: string,
  propertyId?: number,
) => `<div id="Bs-PricesWidget"></div>
<script id="booking-suedtirol-js" src="https://widget.bookingsuedtirol.com/v2/bundle.js" defer></script>
<script>
    document
    .querySelector("#booking-suedtirol-js")
    .addEventListener("load", () => {
        BookingSüdtirol.Widgets.Prices("#Bs-PricesWidget", {
            id: "${widgetId}",
            propertyId: ${propertyId},
            lang: "de",
        });
    });
</script>`;

const SpecialsSourceCode = (
  widgetId: string,
  propertyId?: number,
) => `<div id="Bs-SpecialsWidget"></div>
<script id="booking-suedtirol-js" src="https://widget.bookingsuedtirol.com/v2/bundle.js" defer></script>
<script>
    document
    .querySelector("#booking-suedtirol-js")
    .addEventListener("load", () => {
        BookingSüdtirol.Widgets.Specials("#Bs-SpecialsWidget", {
            id: "${widgetId}",
            propertyId: ${propertyId},
            lang: "de",
            privacyURL: "/path/to/privacy/page",
            termsURL: "/path/to/terms/page",
        });
    });
</script>`;

const RoomsSourceCode = (
  widgetId: string,
  propertyId?: number,
) => `<div id="Bs-RoomsWidget"></div>
<script id="booking-suedtirol-js" src="https://widget.bookingsuedtirol.com/v2/bundle.js" defer></script>
<script>
    document
    .querySelector("#booking-suedtirol-js")
    .addEventListener("load", () => {
        BookingSüdtirol.Widgets.Rooms("#Bs-RoomsWidget", {
            id: "${widgetId}",
            propertyId: ${propertyId},
            lang: "de",
            privacyURL: "/path/to/privacy/page",
            termsURL: "/path/to/terms/page",
        });
    });
</script>`;

const WeatherSourceCode = (
  widgetId: string,
  propertyId?: number,
) => `<div id="Bs-WeatherWidget"></div>
<script id="booking-suedtirol-js" src="https://widget.bookingsuedtirol.com/v2/bundle.js" defer></script>
<script>
    document
    .querySelector("#booking-suedtirol-js")
    .addEventListener("load", () => {
        BookingSüdtirol.Widgets.Weather("#Bs-WeatherWidget", {
            id: "${widgetId}",
            propertyId: ${propertyId},
            lang: "de",
        });
    });
</script>`;

const PortalSourceCode = (widgetId: string) => `<div id="Bs-PortalWidget"></div>
<script id="booking-suedtirol-js" src="https://widget.bookingsuedtirol.com/v2/bundle.js" defer></script>
<script>
    document
    .querySelector("#booking-suedtirol-js")
    .addEventListener("load", () => {
        BookingSüdtirol.Widgets.Portal("#Bs-PortalWidget", {
            id: "${widgetId}",
            lang: "de",
            privacyURL: "/path/to/privacy/page",
            termsURL: "/path/to/terms/page",
        });
    });
</script>`;

const slugs: Record<HBK.Widget.Type, string> = {
  booking: "booking",
  enquiry: "enquiry",
  quick_enquiry: "quick-enquiry",
  special: "specials",
  room: "rooms",
  prices: "prices",
  weather: "weather",
  portal: "portal",
};

const getDocsURL = ({
  id,
  type,
  propertyId,
}: {
  id: string;
  type: HBK.Widget.Type;
  propertyId: number | undefined;
}): URL => {
  const url = new URL(
    `https://developers.bookingsuedtirol.com/docs/widgets/${slugs[type]}`,
  );
  url.searchParams.set("id", id);
  if (propertyId && type !== "portal") {
    url.searchParams.set("propertyId", String(propertyId));
  }

  return url;
};
