import classNames from "classnames";
import { ReactNode } from "react";
import { FieldArrayWithId, FieldValues } from "react-hook-form";
import Button from "./Button";
import Confirm from "./Confirm";
import styles from "./Selected.module.css";

interface ListProps<T extends FieldValues> {
  fields: FieldArrayWithId<T>[];
  controls?: ReactNode;
  direction?: "top" | "bottom";
  children: (props: { field: FieldArrayWithId<T>; index: number }) => ReactNode;
}

const List = <T extends FieldValues>({
  fields,
  controls,
  direction = "top",
  children,
}: ListProps<T>) => (
  <div
    className={classNames(styles.list, {
      [styles.top]: direction === "top",
      [styles.bottom]: direction === "bottom",
    })}
  >
    {fields.length > 0 && (
      <div
        className={classNames(styles.items, {
          [styles.top]: direction === "top",
          [styles.bottom]: direction === "bottom",
        })}
      >
        {fields.map((field, index) => (
          <div key={field.id}>{children({ field, index })}</div>
        ))}
      </div>
    )}
    {controls}
  </div>
);

interface ItemProps {
  name: string;
  disabled?: boolean;
  onDelete: () => void;
}

const Item = ({ name, disabled = false, onDelete }: ItemProps) => {
  return (
    <div className={styles.item}>
      {name}
      <Button
        layout="text"
        buttonProps={{
          onClick: onDelete,
          disabled,
        }}
      >
        Entfernen
      </Button>
    </div>
  );
};

interface ConfirmItemProps extends ItemProps {
  confirm: {
    title: string;
    description?: ReactNode;
  };
}

const ConfirmItem = ({ confirm, ...props }: ConfirmItemProps) => {
  return (
    <Confirm {...confirm} onConfirm={props.onDelete}>
      {({ toggle }) => <Item {...props} onDelete={toggle} />}
    </Confirm>
  );
};

const Selected = {
  Item,
  ConfirmItem,
  List,
};

export default Selected;
