import { useId, useMemo, useState } from "react";
import useListFilters from "../../hooks/useListFilters";
import { usePortalProperties } from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import Combobox from "../../ui/Combobox";
import styles from "./WidgetPropertySelection.module.css";

interface Props {
  portalId: string;
  value: HBK.WidgetProperty | undefined;
  onChange: (p: HBK.WidgetProperty | undefined) => void;
}

const useSearch = (items: HBK.WidgetProperty[]) => {
  const [filtered, setFiltered] = useState(items);

  const onSearch = (query: string) =>
    setFiltered(
      query === ""
        ? items
        : items.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase()),
          ),
    );

  return {
    filtered,
    onSearch,
  };
};

const WidgetPropertySelection = ({ portalId, value, onChange }: Props) => {
  const filters = useListFilters();
  const { data } = usePortalProperties(portalId, filters.state);

  useMemo(
    () => data && data.properties[0] && onChange(data.properties[0]),
    [data],
  );

  if (!data?.properties || !value) return null;

  return (
    <WidgetPropertyCombobox
      properties={data.properties}
      value={value}
      onChange={onChange}
    />
  );
};

interface WidgetComboboxProps {
  properties: HBK.AssignedProperty[];
  value: HBK.WidgetProperty;
  onChange: (p: HBK.WidgetProperty | undefined) => void;
}

const WidgetPropertyCombobox = ({
  properties,
  value,
  onChange,
}: WidgetComboboxProps) => {
  const { filtered, onSearch } = useSearch(properties);

  return (
    <div className={styles.selection}>
      <Combobox
        labelId={useId()}
        items={[...filtered.sort((a, b) => (a.name < b.name ? -1 : 1))]}
        onChange={onChange}
        value={value}
        isDisabled={properties.length === 1}
        displayValue={(prop) => prop.name}
        onInputChange={onSearch}
        placeholder="Unterkunft auswählen"
      />
    </div>
  );
};

export default WidgetPropertySelection;
