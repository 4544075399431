import classNames from "classnames";
import { JSX, Ref } from "react";
import inputStyles from "./Input.module.css";

type Props = JSX.IntrinsicElements["select"] & {
  isInvalid?: boolean;
  ref: Ref<HTMLSelectElement>;
};

const Select = ({ ref, isInvalid, ...props }: Props) => (
  <select
    {...props}
    ref={ref}
    className={classNames(inputStyles.input, props.className, {
      [inputStyles.invalid]: isInvalid,
    })}
  />
);

export default Select;
