import { useReducer } from "react";
import { FieldError } from "react-hook-form";

export interface Upload {
  file: File;
  objectURL: string;
  error: FieldError | undefined;
  isUploading: boolean;
}

type UploadState = Record<string, Upload>;

type Action =
  | { type: "ADD_FILES"; files: File[] }
  | { type: "START_UPLOAD"; upload: Upload }
  | { type: "SET_UPLOAD_ERROR"; upload: Upload; error: unknown }
  | { type: "FINISH_UPLOAD"; upload: Upload };

const reducer = (state: UploadState, action: Action): UploadState => {
  switch (action.type) {
    case "ADD_FILES": {
      return action.files.reduce<UploadState>((state, file) => {
        const objectURL = URL.createObjectURL(file);

        return {
          ...state,
          [objectURL]: {
            file,
            objectURL,
            error: validateFile(file),
            isUploading: false,
          },
        };
      }, state);
    }
    case "START_UPLOAD":
      return {
        ...state,
        [action.upload.objectURL]: { ...action.upload, isUploading: true },
      };
    case "SET_UPLOAD_ERROR":
      return {
        ...state,
        [action.upload.objectURL]: {
          ...action.upload,
          error: {
            type: "serverError",
            message:
              "Beim Hochladen des Fotos ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.",
          },
        },
      };
    case "FINISH_UPLOAD": {
      const { [action.upload.objectURL]: _toDelete, ...rest } = state;
      return rest;
    }
  }
};

export const allowedMimeTypes = ["image/jpeg"];

const validateFile = (file: File): FieldError | undefined => {
  if (file.size < 50000) {
    return {
      type: "minSize",
      message:
        "Die Datei ist zu klein. Bitte laden Sie ein Foto hoch, das größer als 50 KB ist.",
    };
  }

  if (file.size > 2.5e7) {
    return {
      type: "maxSize",
      message: "Hochgeladene Dateien dürfen nicht größer als 25 MB sein.",
    };
  }

  if (!allowedMimeTypes.includes(file.type)) {
    return {
      type: "wrongMimeType",
      message:
        "Sie können Bilder nur im JPEG-Format hochladen. Überprüfen Sie den Dateityp und versuchen Sie es dann noch einmal.",
    };
  }

  return;
};

const usePhotoUpload = () => {
  const [uploadState, dispatch] = useReducer(reducer, {});

  return {
    state: uploadState,
    addFiles: (files: File[]) => dispatch({ type: "ADD_FILES", files }),
    start: (upload: Upload) => dispatch({ type: "START_UPLOAD", upload }),
    fail: (upload: Upload, error: unknown) =>
      dispatch({ type: "SET_UPLOAD_ERROR", upload, error }),
    finish: (upload: Upload) => dispatch({ type: "FINISH_UPLOAD", upload }),
  };
};

export default usePhotoUpload;
