import { User } from "../types/index";
import NotificationBadge from "./NotificationBadge";
import styles from "./UserCard.module.css";

type Layout = "compact" | "full" | "none";

interface Props {
  user: User;
  layout?: Layout;
  notificationCount?: number;
}

const getInitialChar = (s: string): string => {
  return s.charAt(0).toUpperCase();
};

const getUserInitials = (name: string) => {
  const split = name.split(" ");
  const numNames = split.length;

  switch (numNames) {
    case 1:
      return getInitialChar(split[0]!);
    default: {
      const first = split[0]!;
      const last = split[numNames - 1]!;
      return getInitialChar(first) + getInitialChar(last);
    }
  }
};

const UserCard = ({ user, layout = "full", notificationCount }: Props) => {
  const showTraits = layout === "full" || layout === "none";

  return (
    <div className={styles.user}>
      {layout !== "none" && (
        <div className={styles.avatar}>
          {user.photo ? (
            <img
              width={40}
              height={40}
              src={user.photo}
              alt={`Profilbild von ${user.name}`}
              className={styles.image}
            />
          ) : (
            <div className={`${styles.image} ${styles.defaultImage}`}>
              {getUserInitials(user.name ?? user.email)}
            </div>
          )}
          {!!notificationCount && (
            <NotificationBadge className={styles.notificationBadge} color="red">
              {notificationCount}
            </NotificationBadge>
          )}
        </div>
      )}
      {showTraits && (
        <div className={styles.identity}>
          <div className={styles.name}>{user.name ?? user.email}</div>
          {user.name && <div className={styles.email}>{user.email}</div>}
        </div>
      )}
    </div>
  );
};

export default UserCard;
