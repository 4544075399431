import { use } from "react";
import ErrorImage from "../../assets/error.svg";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { useReservationPolicyIdParam } from "../../hooks/useRouterParam";
import {
  HBK,
  patchReservationPolicy,
  useReservationPolicy,
} from "../../http/dashboardApi";
import i18n from "../../i18n";
import EmptyPage from "../../pages/EmptyPage";
import Page from "../../pages/Page";
import Badge from "../../ui/Badge";
import Form from "../../ui/Form";
import ReservationPolicyFormFields from "./ReservationPolicyFormFields";

const ReservationPolicy = () => {
  const policyId = useReservationPolicyIdParam();
  const { data: policy, error } = useReservationPolicy(policyId);
  const dispatch = use(NotificationContext);

  if (error) {
    return (
      <EmptyPage
        title="Nicht gefunden"
        image={ErrorImage}
        backLinkText={i18n.navigation.backToOverview}
      >
        Die Reservierungsbedingung konnte nicht gefunden werden.
      </EmptyPage>
    );
  }

  if (!policy) return null;

  return (
    <Page
      title={policy.name}
      backLinkText={i18n.navigation.backToOverview}
      description={policy.is_readonly && <Badge>Schreibgeschützt</Badge>}
    >
      <Form<HBK.ReservationPolicyBody>
        defaultValues={policy}
        onSubmit={(values) =>
          patchReservationPolicy(policy.id, values)
            .then(() => dispatch(showSuccessNotification()))
            .catch((error: unknown) => dispatch(showErrorNotification(error)))
        }
        disabled={policy.is_readonly}
      >
        {(props) => (
          <ReservationPolicyFormFields
            {...props}
            isReadonly={policy.is_readonly}
          />
        )}
      </Form>
    </Page>
  );
};

export default ReservationPolicy;
