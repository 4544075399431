import { use } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import useToggle, { UseToggleProps } from "../../hooks/useToggle";
import {
  Admin,
  deletePortal,
  importEasyChannelSources,
  postPortal,
  usePortals,
} from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import Page from "../../pages/Page";
import Badge from "../../ui/Badge";
import Button, { AddButton } from "../../ui/Button";
import Confirm from "../../ui/Confirm";
import Controls from "../../ui/Controls";
import DateTimeField from "../../ui/DateTimeField";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";
import Modal from "../../ui/Modal";
import Table from "../../ui/Table";
import Textarea from "../../ui/Textarea";
import SvgBlock from "../../ui/icon/Block.svg?react";
import SvgSync from "../../ui/icon/Sync.svg?react";

const Portals = () => {
  const filters = useListFilters();
  const modal = useToggle();
  const navigate = useNavigate();
  const dispatch = use(NotificationContext);
  const { data, isValidating, mutate } = usePortals(filters.state);
  const canWrite = usePermissions(HBK.Permission.AdminPortalWrite);

  return (
    <Page
      title="Portale"
      description="Portale ermöglichen die Buchung für Unterkünfte unter einer gemeinsamen Oberfläche."
      controls={
        canWrite && (
          <>
            <Button
              layout="hollow"
              glyph={SvgSync}
              buttonProps={{
                title: "Easy Channel Quellen importieren",
                onClick: () => {
                  importEasyChannelSources()
                    .then(() => {
                      dispatch(
                        showSuccessNotification(
                          "Die Easy Channel Quellen wurden erfolgreich importiert.",
                        ),
                      );
                    })
                    .catch((error: unknown) =>
                      dispatch(showErrorNotification(error)),
                    );
                },
              }}
            >
              Easy Channel Quellen importieren
            </Button>
            <AddPortal
              modal={modal}
              onSubmit={(body) =>
                postPortal(body)
                  .then((res) => {
                    modal.close();
                    navigate(res.id);
                    dispatch(showSuccessNotification());
                  })
                  .catch((error: unknown) =>
                    dispatch(showErrorNotification(error)),
                  )
              }
            />
          </>
        )
      }
    >
      <Table
        head={
          <tr>
            <th>Name</th>
            <th />
            <th>Easy Channel Quelle</th>
            <th>Erstellt am</th>
            {canWrite && <th />}
          </tr>
        }
        isValidating={isValidating}
        total={data?.total ?? 0}
        filters={filters}
        body={({ styles }) =>
          data?.portals.map((p) => {
            return (
              <tr key={p.id}>
                <td>
                  <Link to={p.id}>{p.name}</Link>
                </td>
                <td>
                  ID: <Badge>{p.id}</Badge>
                </td>
                <td>
                  {p.easy_channel.name} ({p.easy_channel.id})
                </td>
                <td>
                  <DateTimeField dateTime={p.created_at} />
                </td>
                <td className={styles.alignRight}>
                  <Confirm
                    title="Portal deaktivieren"
                    description={
                      <p>
                        Möchten Sie das Portal <strong>{p.name}</strong>{" "}
                        wirklich deaktivieren? Historische Daten bleiben bei
                        diesem Vorgang erhalten.
                      </p>
                    }
                    onConfirm={() =>
                      deletePortal(p.id).then(
                        () => (mutate(), filters.setPage(1)),
                      )
                    }
                  >
                    {({ open }) => (
                      <Controls>
                        <Controls.Group>
                          <Controls.Link to={`${p.id}/general`}>
                            Allgemein
                          </Controls.Link>
                          <Controls.Link to={`${p.id}/properties`}>
                            Unterkünfte
                          </Controls.Link>
                          <Controls.Link to={`${p.id}/groups`}>
                            Gruppen
                          </Controls.Link>
                          <Controls.Link to={`${p.id}/widgets`}>
                            Widgets
                          </Controls.Link>
                        </Controls.Group>
                        {canWrite && (
                          <Controls.Group>
                            <Controls.Item
                              glyph={SvgBlock}
                              onClick={open}
                              type="danger"
                            >
                              Portal deaktivieren
                            </Controls.Item>
                          </Controls.Group>
                        )}
                      </Controls>
                    )}
                  </Confirm>
                </td>
              </tr>
            );
          }) ?? []
        }
      />
    </Page>
  );
};

export default Portals;

interface AddPortalProps {
  modal: UseToggleProps;
  onSubmit: (props: Admin.PostPortalBody) => void;
}

const AddPortal = ({ modal, onSubmit }: AddPortalProps) => (
  <>
    <AddButton buttonProps={{ onClick: modal.open }}>Neues Portal</AddButton>
    {modal.isOpen && (
      <Modal<Admin.PostPortalBody>
        title="Portal erstellen"
        isOpen={modal.isOpen}
        onClose={modal.close}
        onSubmit={onSubmit}
      >
        {({ register, formState: { errors } }) => (
          <>
            <FormField label="Name" error={errors.name}>
              {({ labelId, required, isInvalid }) => (
                <Input
                  id={labelId}
                  placeholder="Einen Namen vergeben"
                  {...register("name", {
                    required,
                  })}
                  isInvalid={isInvalid}
                />
              )}
            </FormField>
            <FormField
              label="Beschreibung"
              helpText="Die Beschreibung sollte aussagekräftig sein und den Verwendungszweck des Portals widerspiegeln."
            >
              {({ labelId }) => (
                <Textarea
                  id={labelId}
                  rows={3}
                  placeholder="Eine Beschreibung hinzufügen"
                  {...register("description")}
                />
              )}
            </FormField>
            <FormField
              label="Easy Channel Quelle"
              error={errors.easy_channel?.id}
              helpText="Die ID wird benötigt, um bei der Buchung die MSS-Buchungsquelle richtig zuzuweisen."
            >
              {({ labelId, required, isInvalid }) => (
                <Input
                  id={labelId}
                  {...register(`easy_channel.id`, {
                    valueAsNumber: true,
                    required,
                    min: 1,
                  })}
                  type="number"
                  isInvalid={isInvalid}
                />
              )}
            </FormField>
          </>
        )}
      </Modal>
    )}
  </>
);
