import { useId } from "react";
import Input, { Props as InputProps } from "./Input";
import styles from "./TimeRangeSelect.module.css";

interface Props {
  labelId: string;
  startProps?: InputProps;
  endProps?: InputProps;
}

const TimeRangeSelect = ({ labelId, startProps, endProps }: Props) => {
  const toLabelId = useId();

  return (
    <div className={styles.timeRange}>
      <div className={styles.input}>
        <label htmlFor={labelId}>Von</label>
        <Input {...startProps} type="time" id={labelId} />
      </div>
      <div className={styles.input}>
        <label htmlFor={toLabelId}>bis</label>
        <Input {...endProps} type="time" id={toLabelId} />
      </div>
    </div>
  );
};

export default TimeRangeSelect;
